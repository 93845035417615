const sample = require('./sample');
const msmp = require('./msmp');
const tmp = require('./tmp');
const fsmp = require('./fsmp');

module.exports = {
  sample,
  msmp,
  fsmp,
  tmp
};
