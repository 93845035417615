/**
 * Source: https://github.com/CondeNast/verso/blob/2191f4e5b622f7426a530212e69fa8de6cad3545/src/components/helpers/paywall/withVisibilityState.js#L17-L26
 * isNodeViewable
 *
 * This function is used to detect when a DOM element is not viewable
 * due to CSS visibility manipulations such as 'visibility', 'opacity', or
 * even the element being dimensionless (zero width or height).
 *
 * @param {Window} window - the window object
 * @param {HTMLElement} node - the observed HTML element
 *
 * @returns {boolean} is the observed node viewable
 */
const isViewable = (window, node) => {
  const { opacity, visibility } = window.getComputedStyle(node);

  return !!node.offsetHeight && !!node.offsetWidth && opacity !== '0' && visibility !== 'hidden';
};

module.exports = { isViewable };
