const rules = require('./rules');
const eventLogger = require('../../../shared/eventLogger');
const jwt = require('../../../shared/jwt');

const execute = ({ rule, args }) => {
  const payload = jwt.payloadFor('sample');
  const { isExpired } = rules[rule];
  if (!payload || !isExpired(payload, args)) return;

  jwt.remove('sample');
  eventLogger.add({
    type: 'sample-expired',
    payload
  });
};

module.exports = {
  execute
};
