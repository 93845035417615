const bus = require('../../shared/bus');
const negotiators = require('./negotiators');
const negotiatorFor = (form) => negotiators[form];
const noAcceptableFormOfPayment = '';
const { isPaywalled } = require('./classifiers');
const { getCurrentContent } = require('../content');
const { emitAnalyticsEvent } = require('./emitAnalyticsEvent');
const { log } = require('../../shared/errorLogger');

// This variable holds the accepted payment after negotiation
let acceptedPaymentForm;
let acceptedPaymentScope;
let counterName;

const conditionsOfPayment = (form) =>
  ({
    access: () => true,
    sub: () => true,
    tmp: () => true,
    pass: () => true,
    sess: () => true,
    asmp: (content) => isPaywalled(content),
    usmp: (content) => isPaywalled(content),
    sample: (content) => isPaywalled(content),
    msmp: (content) => isPaywalled(content),
    fsmp: (content) => isPaywalled(content),
    free: (content) => !isPaywalled(content)
  })[form];

/**
 * paymentNegotiation - determine an acceptable form of payment
 *
 * This action should be targeted to content that qualifies for the specified
 * payment forms.
 *
 * @param {string[]} paymentForms - the ordered list of acceptable payment forms
 * @param {string[]} acceptableScopes - the list of acceptable scopes for passes
 * @param {string} signature - a unique base64url encoded string
 * @param {string} key - a unique signing key
 * @param {object} props - token properties
 * @param {object[]} receipts - array of receipt objects where each receipt contains a provider and a product
 * @param {object} state
 * @param {object} campaign - current campaign
 * @param {boolean} reset - flag to reset the module variables
 * @param {object} fsmp - object containing details required by fsmp method
 * @returns {undefined}
 */
const paymentNegotiation = (args) => {
  const {
    paymentForms,
    acceptableScopes,
    signature,
    key,
    props,
    receipts,
    state,
    campaign = {},
    reset = false,
    fsmp = {
      clientCredentials: '',
      serviceURL: ''
    }
  } = args;
  if (reset) {
    acceptedPaymentForm = undefined;
    acceptedPaymentScope = undefined;
  }
  fsmp.paymentNegotiation = () =>
    paymentNegotiation({
      ...args,
      reset: true
    });
  const content = getCurrentContent();
  const acceptorFor = (form) =>
    negotiatorFor(form)({ acceptableScopes, signature, key, props, receipts, state, fsmp });
  const acceptPayment = (form) => acceptorFor(form)();
  const isPaymentKnown = (form) => negotiatorFor(form);
  const isPaymentTendered = (form) => acceptorFor(form);
  const isPaymentAcceptable = (form) => conditionsOfPayment(form)(content);

  const acceptableFormOfPayment = paymentForms
    .concat('free')
    .filter(isPaymentKnown)
    .filter(isPaymentTendered)
    .find(isPaymentAcceptable);
  const payment = acceptableFormOfPayment || noAcceptableFormOfPayment;
  if (acceptedPaymentForm === payment) return;

  const updatePayment = ({ scope } = {}) => {
    acceptedPaymentForm = payment;
    acceptedPaymentScope = scope;
    counterName = (props || {}).counter;

    emitAnalyticsEvent({
      event: 'user-payment-updated',
      user: {
        paymentMethod: `${payment}${scope ? '-' + scope : ''}`
      },
      campaign
    });

    bus.emitJourneyStateIsUpdated({
      source: 'payment-negotiation',
      state: {
        payment,
        scope
      }
    });
  };

  const accept = payment ? acceptPayment(payment) : Promise.resolve();
  return accept.then(updatePayment).catch((error) => {
    log('** Journey error: Payment acceptance **', error);
  });
};

module.exports = {
  getAcceptedPaymentForm: () => acceptedPaymentForm,
  getAcceptedPaymentScope: () => acceptedPaymentScope,
  getCounterName: () => counterName,
  resetAcceptedPaymentForm: () => (acceptedPaymentForm = undefined),
  paymentNegotiation
};
