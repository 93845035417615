/**
 * JWT codec - NONE signing algorithm
 *
 * This codec is designed to work with JSON web tokens in the browser.
 * Tokens are signed using a fixed signature set via configuration
 * without consideration of the payload.
 */

const base64url = require('./base64url');

/**
 * encode - encode an object into a JWT
 *
 * @param {object} payload - the token payload
 * @param {string} - signature (optional) - a base64 string to sign tokens
 *
 * @returns {Promise<string>} JWT | Error message
 */
function encode({ payload, signature = '' }) {
  const header = {
    alg: 'NONE',
    typ: 'JWT',
    ver: 1
  };

  let jsonHeader, jsonPayload;
  try {
    jsonHeader = JSON.stringify(header);
    jsonPayload = JSON.stringify(payload);
  } catch (error) {
    return Promise.reject('Cannot JSON encode the JWT');
  }

  let encodedHeader, encodedPayload;
  try {
    encodedHeader = base64url.encode(jsonHeader);
    encodedPayload = base64url.encode(jsonPayload);
  } catch (error) {
    return Promise.reject('Cannot base64url encode the JWT');
  }

  return Promise.resolve(`${encodedHeader}.${encodedPayload}.${signature}`);
}

/**
 * decode - extract the payload from a JWT into an object
 *
 * @param {string} token - a JWT
 * @returns {Object | undefined} payload
 */
function decode({ token }) {
  if (!token) {
    console.warn('Cannot decode a missing JWT token');
    return;
  }

  const [, payload] = token.split('.');
  if (!payload) {
    console.warn('Cannot decode an improperly formatted JWT token');
    return;
  }

  let jsonString;
  try {
    jsonString = base64url.decode(payload);
  } catch (error) {
    console.warn('Cannot base64 decode the JWT token');
    return;
  }

  let data;
  try {
    data = JSON.parse(jsonString);
  } catch (error) {
    console.warn('Cannot parse the JWT token as JSON');
    return;
  }

  return data;
}

/**
 * verify - verify the signature of a JWT
 *
 * @param {string} token - a JWT
 * @param {string} signature - a base64 string
 * @returns {Promise<boolean>} is the signature verified
 */
function verify({ token, signature }) {
  if (!token) return Promise.reject('Cannot verify a missing JWT token');
  if (!signature) return Promise.reject('Cannot verify without a signature');

  const [, , tokenSignature] = token.split('.');
  if (!tokenSignature) return Promise.reject('Cannot verify an improperly formatted JWT token');

  return Promise.resolve(tokenSignature === signature);
}

/**
 * supports - determine if a token is supported by this codec
 *
 * @param {string} string - a JWT
 * @returns {boolean} token is supported
 */
function supports({ token }) {
  if (!token) return false;

  const [header] = token.split('.');
  if (!header) return false;

  try {
    const jsonString = base64url.decode(header);
    const data = JSON.parse(jsonString);
    const { alg, typ, ver } = data;
    return alg === 'NONE' && typ === 'JWT' && ver === 1;
  } catch (error) {
    return false;
  }
}

/**
 * available - can the codec be used
 *
 * @param {object} config - codec configuration properties
 * @returns {boolean} codec is supported
 */
const available = (config) => !!config.signature;

module.exports = { name: 'NONE', encode, decode, verify, supports, available };
