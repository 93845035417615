/**
 * token expiration - after rule
 *
 * This rule should be provided with two arguments: 'time' and 'since'.
 *
 * time (string) - must be a time period, for instance: 10 days, 30 minutes, 20 hours, etc.
 * since (string) - the supported 'since' values are 'created' and 'updated'
 *
 * Usage examples:
 *
 * - rule to expire a token after 30 days since it was created.
 * {
 *  "rule": "after",
 *  "time": "30 days",
 *  "since": "created"
 * }
 *
 * - rule to expire a token after 2 months since it was last updated.
 * {
 *  "rule": "after",
 *  "time": "2 months",
 *  "since": "updated"
 * }
 *
 */
const ms = require('ms');
const dateTime = require('../../../../shared/dateTime');

const compareWith = (key) => (payload, time) => dateTime.now().getTime() - payload[key] > ms(time);
const comparators = {
  created: compareWith('iat'),
  updated: compareWith('uat')
};

const isExpired = (payload, { since, time }) => comparators[since](payload, time);

module.exports = { isExpired };
