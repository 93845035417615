const cookie = require('../../../shared/cookie');

/*
 * Sub payment form negotiator
 *
 * The sub payment form is acceptable when
 *   - the token is present
 */
const sub = () => {
  const tokenName = 'pay_ent_sub';

  const token = cookie.read(tokenName);
  if (!token) return;

  return () => Promise.resolve();
};

module.exports = {
  sub
};
