/**
 * Generates user bucket number for a given ID
 *
 * https://www.npmjs.com/package/crc-32
 */
const crc32 = require('crc-32');

/**
 * For a given xid, a bucket number is generated
 * defaults to DEFAULT_BUCKET
 *
 * @param {string} xid - CN_xid
 * @returns {number} - bucket number
 */
const getBucketNumber = (xid) => {
  const DEFAULT_BUCKET = 100;
  if (!xid) {
    return DEFAULT_BUCKET;
  }

  try {
    const hash = crc32.str(xid);
    return Math.abs(hash % 100) + 1;
  } catch (err) {
    console.error('getBucket', err);
    return DEFAULT_BUCKET;
  }
};

module.exports = {
  getBucketNumber
};
