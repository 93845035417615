const expirers = require('./expirers');
const expirerFor = (form) => expirers[form];

const tokenExpiration = ({ paymentForm, rule, state, ...args }) => {
  const expirer = expirerFor(paymentForm);
  expirer.execute({ rule, args, state });
};

module.exports = {
  tokenExpiration
};
