const { get, deepClone } = require('./jdash');
const types = require('./types');
const { typeOf } = require('./unitType');

/**
 * injector - This module is responsible for injecting configuration values into
 * units string fields that are annotated with set variables `{{ variable }}`.
 *
 * unit.data = {
 *   subscribe: {
 *     url: {
 *       desktop: 'https://subscribe.vanityfair.com/subscribe/vanityfair/144499?source=JNY_{{ brand }}_DESKTOP_{{ unit }}_0_{{ campaign }}'
 *     }
 *   }
 * }
 *
 * campaign.configuration = {
 *   set: {
 *     brand: 'VYF',
 *     campaign: 'WINTER_FEB23_S_ZZ'
 *   }
 * }
 *
 * unit.configuration = {
 *   set: {
 *     unit: 'PAYWALL_METER_ARTICLE_1'
 *   }
 * }
 */

const reviver = (dictionary) => (_, value) => {
  if (typeof value !== 'string') return value;

  return value.replace(/{{\s*(.*?)\s*}}/g, (_, variable) => get(dictionary, variable));
};

/**
 * set - inject values into a unit with the following priority, sorted from lowest to highest:
 *
 *   tracking parameters
 *   state values
 *   module configuration set values
 *   module parameters set in the including campaign
 *   campaign configuration set values
 *   template or component configuration set values
 *   unit configuration set values
 *
 * @param {object} campaign - campaign
 * @param {object} module (optional) - module containing unit
 * @param {object} unit - unit
 * @param {Object} state (optional) - the current state
 * @returns {object} cloned unit updated with injected values
 */
const set = ({ campaign = {}, module = {}, unit = {}, state = {} }) => {
  if (types.isARuleUnit(unit)) return unit;

  const dictionary = Object.assign(
    {
      tracking: {
        unitType: typeOf(unit).toUpperCase()
      }
    },
    { state },
    get(module, 'configuration.set', {}),
    get(campaign, 'configuration.includes', {})[module.key] || {},
    get(campaign, 'configuration.set', {}),
    get(unit, 'template.configuration.set', {}),
    get(unit, 'component.configuration.set', {}),
    get(unit, 'configuration.set', {})
  );
  return deepClone(unit, reviver(dictionary));
};

module.exports = {
  set
};
