const cookie = require('../../../shared/cookie');
const { codecFor, bestAvailableCodec } = require('../../../shared/codecs');
const { oneYearInSeconds } = require('../../../shared/dateTime');
const { log } = require('../../../shared/errorLogger');

const isNumber = (value) => typeof value === 'number';
const notValid = (payload) => !payload || !isNumber(payload.cnt) || !Array.isArray(payload.urls);
const notPreviouslyViewed = (content, payload) => !payload.urls.includes(content);
const samplesRemaining = (payload) => payload.cnt || 0;
const noSamplesRemain = (payload) => samplesRemaining(payload) >= payload.max;
const add = (content, payload) => {
  if (payload.urls.length < payload.max) payload.urls.push(content);
  payload.cnt += 1;
  payload.uat = Date.now();
};

/*
 * Sample payment form negotiator
 *
 * The sample payment form is acceptable when
 *   - the token is present
 *   - when free samples remain or
 *   - when the content has already been sampled
 *
 * @param {string} signature - a unique base64url encoded string
 * @param {object} props - token properties
 * @param {number} props.max - max number of free samples
 * @returns {function} payment acceptor
 */
const sample = ({ signature, key, props: { max = 4 } }) => {
  const content = window.location.pathname;
  const tokenName = 'pay_ent_smp';

  const token = cookie.read(tokenName);

  let payload;
  if (token) {
    payload = codecFor({ token }).decode({ token, signature });
  }
  if (notValid(payload)) {
    const now = Date.now();
    payload = { cnt: 0, max, urls: [], iat: now, uat: now, iss: 'journey' };
  }
  if (notPreviouslyViewed(content, payload)) {
    if (noSamplesRemain(payload)) return;

    add(content, payload);
  }
  return () => {
    const codec = bestAvailableCodec({ signature, key });
    if (!codec) {
      return;
    }

    return codec
      .encode({ payload, signature, key })
      .then((encodedToken) => {
        if (encodedToken !== token) {
          cookie.write(tokenName, encodedToken, oneYearInSeconds);
        }
      })
      .catch((error) => {
        log('** Journey error: Token Encoding **', error);
      });
  };
};

module.exports = {
  sample
};
