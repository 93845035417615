const { geoFrom } = require('./geolocation');
const eventLogger = require('../shared/eventLogger');

/*
 *  assetTracker - This module is responsible for collecting and
 *  emitting performance metrics for assets used by the Journey Service.
 */

const recordEntry = (entry, id) => {
  const geo = geoFrom(entry);
  eventLogger.add({ type: 'asset-tracker', id, geo, startTime: Math.floor(entry.startTime), duration: Math.floor(entry.duration) });
};

/*
 * processResourceEntries - For every entry generated by the
 * resource timing API attributable to assets used by the Journey service,
 * create a custom performance mark with a name that includes the Fastly node
 * from which the asset was delivered to the user's browser. The custom marks
 * will be delivered to Splunk by existing reporting infrastructure.
 */
const processResourceEntries = (source = performance) =>
  source.getEntries().forEach((entry) => {
    switch (true) {
      case !!entry.name.match(/covers.conde.io/):
        recordEntry(entry, 'cover_manager');
        break;

      case !!entry.name.match(/user-context/):
        recordEntry(entry, 'user_context');
        break;

      case !!entry.name.match(/\/api\/payment\//):
        recordEntry(entry, 'payment_server');
        break;

      case !!entry.name.match(/build-\w+\.js/):
        recordEntry(entry, 'journey_compiler');
        break;

      case !!entry.name.match(/\/gate\/sync\?canonicalUrl/):
        recordEntry(entry, 'journey_gate_sync_meter');
        break;
    }
  });

/*
 * watchForResourceEntries - watch for and process performance
 * entries that might occur after page load.
 */
const watchForResourceEntries = () => {
  const observer = new PerformanceObserver(processResourceEntries);
  observer.observe({ entryTypes: ['resource'] });
};

const execute = () => {
  processResourceEntries();
  watchForResourceEntries();
};

module.exports = {
  execute
};
