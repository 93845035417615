const cookie = require('../../../shared/cookie');
const { codecFor } = require('../../../shared/codecs');

const areScopesVerified = (acceptableScopes, scopes) =>
  scopes && scopes.some((scope) => acceptableScopes.includes(scope));

/*
 * pass payment form negotiator
 *
 * The pass payment form is acceptable when
 *   - the token is present
 *   - the acceptable scopes configured in the payment negotiation
 *     match with the scopes in the decoded token. If no acceptable
 *     scopes are configured then all scopes are acceptable.
 */
const pass = ({ acceptableScopes = [] }) => {
  const tokenName = 'pay_ent_pass';

  const token = cookie.read(tokenName);
  if (!token) return;

  const payload = codecFor({ token }).decode({ token });
  if (!payload || (acceptableScopes.length && !areScopesVerified(acceptableScopes, payload.scopes)))
    return;

  const scope = payload.scopes.find((scope) => acceptableScopes.includes(scope));

  return () => Promise.resolve({ scope });
};

module.exports = {
  pass
};
