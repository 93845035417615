const { log } = require('../logging');
const unblocker = require('../../shared/unblocker');

const query = (selector) =>
  document.querySelector(selector) || document.querySelector(unblocker.selectorFor(selector));

/*
 * Container for consumer marking units that are injected by Journey
 *
 * DOM elements are marked in Verso using the data element 'journey-hook'.
 * A consumer marketing unit is appended to the marked DOM element.
 *
 * The slot is specified using the mark and the desired class name of
 * the injected consumer marketing unit. For example:
 * persistent-bottom > consumer-marketing-unit__registration-gate
 */
const injected = (slot) => {
  const [mark, cmu] = slot.split('>').map((value) => value.trim());

  const selectorFor = (mark) => `[data-journey-hook="${mark}"]`;

  const isSupported = () => slot.includes('>') && slot.split('>').length == 2;

  const locateElement = () => query(`${selectorFor(mark)} .${cmu} .journey-unit`);

  const isElementPresent = () => !!query(selectorFor(mark));

  const createElement = () => {
    const markedElement = query(selectorFor(mark));
    if (!markedElement) {
      log(`Element with data-journey-hook="${mark}" was not found in DOM`);
      return;
    }

    const cmuElement = document.createElement('div');
    cmuElement.className = cmu;
    markedElement.append(cmuElement);

    const journeyElement = document.createElement('div');
    journeyElement.className = 'journey-unit';
    cmuElement.append(journeyElement);

    return journeyElement;
  };

  return {
    isSupported,
    locateElement,
    isElementPresent,
    createElement
  };
};

module.exports = {
  injected
};
