const cookie = require('../shared/cookie');

// We use the same lifetime (2 years) as the existing CN_segments cookie,
// albeit expressed in seconds rather than milliseconds.
// https://github.com/CondeNast/segmentation/blob/master/src/routes/segments/handler.js#L32
const SEGMENTS_COOKIE_TTL_IN_SECONDS = 2 * 365 * 24 * 60 * 60;
const SEGMENTS_COOKIE = 'CN_segments';
/*
 * getSegments - the segments a user is in derived from the segmentation cookie
 *
 * @returns {Array<string>} list of segments
 */
const getSegments = () => {
  const value = cookie.read(SEGMENTS_COOKIE);
  if (!value) return [];

  return value.split('|').map((string) => string.trim());
};

/**
 * setSegments - Add or remove the user to/from segments.
 *
 * @param {string[]} adds - a list of segments to add the user to
 * @param {string[]} removes - a list of segments to remove the user from
 */
const setSegments = ({ adds = [], removes = [] }) => {
  const currentSegments = cookie.read(SEGMENTS_COOKIE) || '';
  const trimmedAdds = adds.map((segment) => segment.trim());
  const trimmedRemoves = removes.map((segment) => segment.trim());

  const newSegments = [
    ...new Set(
      currentSegments
        .split('|')
        .map((segment) => segment.trim())
        .filter((segment) => !!segment && !trimmedRemoves.includes(segment))
        .concat(trimmedAdds)
    )
  ].join('|');

  if (currentSegments !== newSegments) {
    cookie.write(SEGMENTS_COOKIE, newSegments, SEGMENTS_COOKIE_TTL_IN_SECONDS);
  }
};

module.exports = { getSegments, setSegments };
