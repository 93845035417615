const truncateContent = (args) => {
  if (!window.paywallGateway || typeof window.paywallGateway.truncateContent !== 'function') {
    return;
  }

  window.paywallGateway.truncateContent(args);
};

module.exports = {
  truncateContent
};
