const isATemplatePresentationUnit = (unit) => unit.template && !unit.function && !unit.component;
const isAComponentPresentationUnit = (unit) => !unit.template && !unit.function && unit.component;
const isAPresentationUnit = (unit) =>
  isATemplatePresentationUnit(unit) || isAComponentPresentationUnit(unit);
const isAPresentationUnitThatTargets = (slot) => (unit) => unit.slot === slot;

const predicates = {
  action: (record) => record.configuration?.action || !record.configuration?.rule,
  rule: (record) => record.configuration?.rule,
};
const isMarked = (mark = '', record) => {
  const predicate = predicates[mark.toLowerCase()];
  return predicate && predicate(record);
};

const isAnActionUnit = (unit) =>
  isMarked('Action', unit) && unit.function && !unit.component && !unit.template;
const isAnImmediateAction = (unit) => isAnActionUnit(unit) && !unit.configuration.userActions;
const isAnActionThatTargets = (unit) => (action) =>
  isAnActionUnit(action) && action.targeting.units && action.targeting.units.includes(unit.name);
const isAnActionThatPrevents = (unit) => (action) =>
  isAnActionUnit(action) && action.data.prevent && action.data.prevent.includes(unit.name);

const isARuleUnit = (unit) =>
  isMarked('Rule', unit) && unit.function && !unit.component && !unit.template;

module.exports = {
  isATemplatePresentationUnit,
  isAnActionUnit,
  isAComponentPresentationUnit,
  isAPresentationUnit,
  isAnImmediateAction,
  isAPresentationUnitThatTargets,
  isAnActionThatTargets,
  isAnActionThatPrevents,
  isARuleUnit
};
