const { existing } = require('./existing');
const { injected } = require('./injected');

const containers = [existing, injected];

const containerFor = (slot) => {
  const container = containers.find((container) => container(slot).isSupported());
  if (!container) return;

  return container(slot);
};

module.exports = {
  containerFor
};
