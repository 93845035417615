/**
 * getCurrentContent
 *
 * This function returns a content object containing properties obtained from preloaded state.
 * https://github.com/CondeNast/verso/blob/main/src/core/Layout.tsx#L156
 *
 * @returns {Object} - content object
 */
const getCurrentContent = () => {
  const preloadedState = window.__PRELOADED_STATE__;
  if (!preloadedState) return {};

  const payment = preloadedState?.transformed?.payment;
  return {
    ...payment?.negotiation?.content,
    tags: [
      ...(payment?.negotiation?.content?.tags || []),
      ...(payment?.negotiation?.content?.functionalTags || []),
      ...(window.cns?.pageContext?.keywords?.tags || [])
    ]
  };
};

module.exports = {
  getCurrentContent
};
