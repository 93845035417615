const engine = require('./engine');

const matching = (state) => (unit) => engine && engine.matching(unit, state);
const byScore = (a, b) => engine.score(a) - engine.score(b);

/**
 * matchingUnit - which unit in an array of units best matches the targeted state
 *
 * @param {Array<Object>} units - an array of journey units
 * @param {Object} state - the targeted state
 * @returns {Object} the best matching unit
 */
function matchingUnit(units, state) {
  return units.filter(matching(state)).sort(byScore).pop();
}

/**
 * matchingUnits - the units in an array of units that match the targeted state
 *
 * @param {Array<Object>} units - an array of journey units
 * @param {Object} state - the targeted state
 * @returns {Array<Object>} the matching units
 */
function matchingUnits(units, state) {
  return units.filter(matching(state));
}

module.exports = {
  matchingUnit,
  matchingUnits
};
