const limitScrolling = ({ selector, items }) => {
  const container = document;
  if (selector) {
    const pageWidth = window.innerWidth;
    let regGateHeight = 180;
    if (pageWidth <= 767) {
      regGateHeight = 320;
    } else if (pageWidth <= 1023) {
      regGateHeight = 254;
    }
    let target = container.getElementsByClassName(selector);
    target = Array.from(target);
    const actualSlides = [];
    target.forEach((slide) => {
      if (slide.classList.length === 1) {
        actualSlides.push(slide);
      }
    });
    target = actualSlides[items];
    let allowedHeight = container.documentElement.scrollHeight;
    const handler = function handleIntersection(entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          allowedHeight = window.pageYOffset + regGateHeight;
          window.scrollTo(0, allowedHeight);
        }
      });
    };
    const option = {
      threshold: 0
    };
    const observer = new IntersectionObserver(handler, option);
    observer.observe(target);
    window.onscroll = function () {
      const pageHeight = window.pageYOffset;
      if (pageHeight > allowedHeight) {
        window.scrollTo(0, allowedHeight);
      }
    };
    return;
  }
};

module.exports = {
  limitScrolling
};
