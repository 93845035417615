const cipher = require('./codecs/aes128ctr');
const uInt8Array = require('./codecs/uInt8Array');

const key = ROTATING_ENDPOINT_KEY;

let cryptoKey;

const createCryptoKey = (config) => {
  if (!cryptoKey) {
    return cipher
      .createCryptoKey(key)
      .then((key) => (cryptoKey = key))
      .then(() => ({ ...config, cryptoKey }));
  }

  return Promise.resolve({ ...config, cryptoKey });
};

const generateIv = (config) => {
  const iv = cipher.generateIv();
  return { ...config, iv };
};

const encryptEndpoint = ({ endpoint, cryptoKey, iv }) =>
  cipher
    .encrypt(cryptoKey, iv, `${location.hostname}${endpoint}`)
    .then((encryptedEndpoint) => `/${uInt8Array.toBase64(iv)}${encryptedEndpoint}`);

const generate = (endpoint) => {
  const config = { key, endpoint };

  if (!cipher.available(config)) return Promise.resolve(endpoint);

  return Promise.resolve(config).then(createCryptoKey).then(generateIv).then(encryptEndpoint);
};

module.exports = {
  generate
};
