const cookie = require('../../../shared/cookie');
const { codecFor } = require('../../../shared/codecs');

/*
 * access payment form negotiator
 *
 * The access payment form is acceptable when
 *   - the token is present
 *   - the product code for a provider in a receipt, configured in the
 *     payment negotiation matches one of the products for that provider in
 *     the decoded token
 *     Receipts are an array of objects, where each object specifies a provider
 *     and a product.
 *     receipts = [
 *       {
 *         provider: 'org-access'
 *         product: 'vogue.com:basic',
 *       }
 *     ]
 */
const access = ({ receipts = [] }) => {
  const tokenName = 'CN_access';

  const token = cookie.read(tokenName);
  if (!token) return;

  const payload = codecFor({ token }).decode({ token });
  if (!payload) return;

  const matchingReceipt = receipts.find((receipt) => {
    const { provider = '', product = '' } = receipt;

    if (!payload.fct || !product) return;

    // if the provider is a wildcard (*), check product codes of all providers
    if (provider === '*') {
      const allEntitledProducts = Object.values(payload.fct).flat();
      return allEntitledProducts.includes(product);
    }

    const entitledProducts = payload.fct[provider] || [];

    return entitledProducts.includes(product);
  });

  if (!matchingReceipt) return;

  return () => Promise.resolve();
};

module.exports = {
  access
};
