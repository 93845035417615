const { uniq } = require('./jdash');

/**
 * unitType
 *
 * This module is responsible for managing the type annotations associated with a unit.
 */

/**
 * unitTypes
 *
 * @param {object} unit - a journey unit
 * @returns {array<string>} - all types associated with this unit
 */
const unitTypes = (unit) =>
  uniq([
    ...(unit.template?.configuration?.types || []),
    ...(unit.component?.configuration?.types || []),
    ...(unit.configuration?.types || []),
    ...(unit.function?.configuration?.types || [])
  ]);

/**
 * typeOf unit
 *
 * @param {object} unit - a journey unit
 * @returns {string} - first type associated with this unit
 */
const typeOf = (unit) => unitTypes(unit)[0] || '';

/**
 * matchesType
 *
 * @param {object} unit - a journey unit
 * @param {array<string>} types - type list
 * @returns {boolean} - whether the unit is one of the listed types
 */
const matchesType = (unit, types) => unitTypes(unit).some((type) => types.includes(type));

module.exports = {
  unitTypes,
  typeOf,
  matchesType
};
