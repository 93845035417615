const node = require('./node');

/**
 * When the DOM element partial appears on the screen
 * it will trigger the callback.
 * Using verso threshold https://github.com/CondeNast/verso/blob/2191f4e5b622f7426a530212e69fa8de6cad3545/src/components/helpers/paywall/withVisibilityState.js#L89
 *
 * @param element {Element} - DOM element to be observe
 * @param callback {Function} - callback will be execute when element appears
 * @param times {number} - how many times is allow to watch the element
 */
const onElementAppearance = (element, callback, times = 1) => {
  let count = 0;
  const observer = new IntersectionObserver(
    ([entry], observer) => {
      if (entry.isIntersecting === true && node.isViewable(window, element)) {
        callback();
        count++;
        if (count >= times) {
          observer.unobserve(element);
        }
      }
    },
    {
      threshold: [0.001, 0.999],
      trackVisibility: true,
      delay: 100
    }
  );
  observer.observe(element);
};

module.exports = { onElementAppearance };
