/**
 * templater - This module is responsible for injecting data into Mustache
 * templates. This is a minimal implementation built for size and speed. It
 * supports only those features in use in Journey templates.
 *
 * {{ escapedVariable }}
 * {{{ unescapedVariable }}}
 * {{# section }} ... {{/ section }}
 * {{^ invertedSection }} ... {{/ invertedSection }}
 *
 */

const bridge = require('./bridge');
const formatter = require('./formatter');
const { get, escape } = require('./jdash');
const device = require('./device');

/**
 * forViewport - determine the value appropriate to the current viewport
 *
 * @param {*} value - an object or a primitive value
 * @returns {*} the viewport appropriate value
 */
const forViewport = (value, viewport = device.viewport) => {
  if (typeof value === 'object' && (value.desktop || value.tablet || value.mobile)) {
    return value[viewport];
  } else {
    return value;
  }
};

/**
 * generateStylesOverride - generates an HTML style tag containing configured
 * overriding CSS style rules.
 *
 * @param {object} data.styles - styles object from the unit data configuration
 * @returns {string} - HTML style tag containing style overrides
 */
const generateStylesOverride = ({ styles = {} }) => {
  const stylesStr = Object.values(styles).join('');
  const safeStyleStr = stylesStr.replace(/(<([^>]+)>)/gi, '');
  if (!safeStyleStr) return '';

  return `<style>${safeStyleStr}</style>`;
};

/**
 * addRedirectURL
 * Adds redirectURL query parameter to provided urls
 * Make sure to always {{{ unescape }}} the url to prevent query parameter characters
 *  such as ampersant to be affected
 * @param {string} value - template url values using addRedirectURL
 * @returns {string} - url with redirect query paramater added
 */
const addRedirectURL = (value) => {
  let url;

  try {
    const isRelativeURL = value.match(/^\//);
    url = new URL(isRelativeURL ? `${window.location.origin}${value}` : value );
  } catch {
    return value;
  }

  const { origin, pathname } = window.location;

  url.searchParams.set('redirectURL', `${origin}${pathname}`);

  return url.href;
};


const knownFunctions = {
  addRedirectURL
};

const parse = (capture, data) => {
  const [variable, name] = capture.match(/[^()\s]+/g).reverse();
  let value = forViewport(get(data, variable));
  if (knownFunctions[name]) value = knownFunctions[name](value);
  return value;
};

/**
 * render - render data into a template
 *
 * @param {string} template - Mustache template
 * @param {object} data - data to inject
 * @returns {string} HTML
 */
const render = (template, data) =>
  template
    .replace(/{{(\^|#)\s*(.*?)\s*}}([\s\S]*?){{\/\s*(\2)\s*}}/gm, (_, section, variable, content) =>
      !forViewport(get(data, variable)) ^ (section === '#') ? content : ''
    )
    .replace(/{{{\s*(.*?)\s*}}}/g, (_, capture) =>
      formatter.apply(parse(capture, data) || '')
    )
    .replace(/{{\s*(.*?)\s*}}/g, (_, capture) =>
      formatter.apply(escape(parse(capture, data)))
    )
    .concat(generateStylesOverride(data));

module.exports = {
  render: (template, data) => render(template, { ...data, bridge: bridge.name, templateData: JSON.stringify(data), viewport: device.viewport })
};
