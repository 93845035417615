const cookie = require('../../shared/cookie');
const dateTime = require('../../shared/dateTime');
const { bestAvailableCodec } = require('../../shared/codecs');
const { log } = require('../../shared/errorLogger');

/**
 * issueToken - creates a token (payment negotiation)
 *
 * @param {string} name - token/cookie name
 * @param {object} payload - JSON token payload
 * @param {string} signature - optional JWT signature
 * @param {string} key - optional signing key
 * @param {number} expirationInSeconds - seconds that this token should live in the browser
 *
 * @returns {undefined} undefined
 */
const issueToken = ({
  name,
  payload,
  signature,
  key,
  expirationInSeconds = dateTime.oneYearInSeconds
}) => {
  const now = dateTime.now().getTime();
  const codec = bestAvailableCodec({ key, signature });
  if (!codec) return;

  return codec
    .encode({ payload: { ...payload, iat: now, uat: now }, key, signature })
    .then((token) => {
      if (!token) return;
      cookie.write(name, token, expirationInSeconds);
    })
    .catch((error) => {
      log('** Journey error: token encoding failure **', error);
    });
};

module.exports = {
  issueToken
};
