const knownGenerators = {
  ...require('./payloadGenerator'),
  ...require('./cniPaymentPayloadGenerator'),
  ...require('./snowplowPayloadGenerator')
};

const generatorFor = (payload) => knownGenerators[payload];

module.exports = {
  generatorFor
};
