const template = require('./template');
const component = require('./component');
const registry = require('../../shared/registry');

const renderers = [template, component];

const rendererFor = (unit) => renderers.find((renderer) => renderer.isSupported(unit));

// Used by paywall panel to locate the DOM element associated with a unit.
const locateElement = (unit) => {
  return rendererFor(unit).locateElement(unit);
};

const render = (unit, ...rest) => {
  return rendererFor(unit).render(unit, ...rest);
};

const empty = (slot, ...rest) => {
  if (registry.isEmpty(slot)) return;

  const unit = registry.unitIn(slot);
  return rendererFor(unit).empty(slot, unit, ...rest);
};

module.exports = {
  locateElement,
  render,
  empty
};
