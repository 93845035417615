/**
 * isPaywalled - determines if the content is paywalled
 *
 * @param {array} tags - content tags
 * @param {boolean} isPreview - is a content preview
 * @returns {boolean} - is content paywalled
 */
const isPaywalled = ({ isPreview, tags }) => {
  if (isPreview) return false;

  return !tags || (!tags.includes('paywall-exclude') && !tags.includes('exclude-reg-gate'));
};

module.exports = {
  isPaywalled
};
