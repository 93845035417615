const registry = require('../../shared/registry');

const truncateMarkedContent = ({ mark, selector, items }) => {
  let container = document;
  if (mark) {
    container = document.querySelector(`[data-journey-hook="${mark}"]`);
    if (!container) return;
  }

  const removeChildren = () => {
    let children;
    if (selector) {
      children = container.querySelectorAll(selector);
    } else {
      children = container.children;
    }
    if (!children) return;

    const journeyElements = registry.elements();

    Array.from(children).forEach((child, index) => {
      if (!journeyElements.some((element) => child.contains(element)) && index >= items && child) {
        if (
          child.matches(
            '[class*="NewsletterSubscribeFormWrapper"], [class*="NewsletterSubscribeFormWrapper"] + p'
          )
        ) {
          // do not remove it from the DOM, instead
          child.innerHTML = '';
          child.style.display = 'none';
        } else if (child.remove) {
          child.remove();
        }
      }
    });
  };

  removeChildren();

  const observer = new MutationObserver((mutations = []) => {
    if (!mutations.some(({ addedNodes = [] }) => addedNodes.length)) return;

    removeChildren();
  });

  observer.observe(container, { childList: true, subtree: true });
};

module.exports = {
  truncateMarkedContent
};
