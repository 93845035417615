
/**
 * Unique ID for the current page view
 *
 *   - Ranges from 0 to 4294967295
 *   - Uses crypto library but falls back to Math library
 *   - Useful for correlating events logged from a single page
 */
const maxUint32 = 4294967295;
const cryptoId = () => {
  try {
    return crypto.getRandomValues(new Uint32Array(1))[0];
  } catch (error) {
    return undefined;
  }
};
const randomId = () => Math.floor(maxUint32 * Math.random());
const id = cryptoId() || randomId();

module.exports = {
  id
};
