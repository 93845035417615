const suppression = require('./suppression');
const resolver = require('./resolver');
const types = require('./types');
const { isUniq } = require('./jdash');
const { unitTypes } = require('./unitType');

/**
 * features - This module is responsible for determining the features and active
 * features for a campaign eg. paywall and intender. Features are computed using distinct
 * presentation unit types of a campaign. Active features are features with unit targeting
 * and suppression taken into account.
 *
 */

const suppressionFilter = (unit) => !suppression.ofPaywall({ unit });

const presentationUnitTypes = ({ campaign, campaigns, state, filter = () => true }) =>
  resolver
    .resolveUnits({ type: types.isAPresentationUnit, campaign, campaigns, state })
    .filter(filter)
    .map(unitTypes)
    .flat()
    .filter(isUniq);

/**
 * features - determine features for a given campaign
 *
 * @param {Object} campaign - given campaign
 * @param {Array<Object>} campaigns - all campaigns
 * @returns {Array<string>} features
 */
const features = (params) => presentationUnitTypes(params);

/**
 * activeFeatures - determine active features for a given campaign
 *
 * @param {Object} campaign - given campaign
 * @param {Array<Object>} campaigns - all campaigns
 * @param {Object} state - the current state
 * @returns {Array<string>} features
 */
const activeFeatures = (params) => presentationUnitTypes({ ...params, filter: suppressionFilter });

module.exports = {
  features,
  activeFeatures
};
