const bus = require('../shared/bus');
const cookie = require('../shared/cookie');
const defeat = require('./defeat');
const eventLogger = require('../shared/eventLogger');
const localStorage = require('../shared/localStorage');

/**
 * Ad Blocker Detector - This module is responsible for determining
 * if an ad blocker is detected on the client or not.
 */

let win = window;
win.setInterval = defeat.setInterval;
win.setTimeout = defeat.setTimeout;
let presence;
const callbacks = [];

const cookieName = 'CN_ab';
const googleAds = "div[id*='google_ads_iframe'] > iframe"; // eslint-disable-line quotes
const areRendered = (selector) => win.document.querySelectorAll(selector).length;

const adsLibrary = () => win.cns?.library;
const notAvailable = (locator) => !locator();

const store = localStorage.storageFor('journey');

const serialize = (value) => {
  switch (value) {
    case true: return '1';
    case false: return '0';
    default: return undefined;
  }
};

const record = (presence) => {
  cookie.write(cookieName, serialize(presence));
  store.write(cookieName, serialize(presence));
};

const deserialize = (value) => {
  switch (value) {
    case '1': return true;
    case '0': return false;
    default: return undefined;
  }
};

const recordedPresence = () => {
  const cookieValue = deserialize(cookie.read(cookieName));
  const storeValue = deserialize(store.read(cookieName));

  // recover cookie from store
  if (storeValue !== undefined && cookieValue === undefined) {
    record(storeValue);
    return storeValue;
  }
  // recover store from cookie
  if (cookieValue !== undefined && storeValue === undefined) {
    record(cookieValue);
    return cookieValue;
  }
  // override store with cookie in case they disagree
  if (
    storeValue !== undefined &&
    cookieValue !== undefined &&
    storeValue !== cookieValue
  ) {
    record(cookieValue);
    return cookieValue;
  }

  if (cookieValue !== undefined) return cookieValue;
  if (storeValue !== undefined) return storeValue;
  return undefined;
};

const setPresence = (value) => {
  if (presence === value) return;

  presence = value;
  eventLogger.add({ type: 'adblock', id: 'change', presence });
  if (presence != recordedPresence()) record(presence);
  callbacks.forEach((callback) => callback(presence));
};

const watch = (condition, action, interval = 1000) => {
  let id = undefined;
  id = win.setInterval(() => {
    if (condition()) {
      win.clearInterval(id);
      action();
    }
  }, interval);
};

/**
 * initialize
 *
 * @returns undefined
 */
const initialize = () => {
  presence = recordedPresence();
  eventLogger.add({ type: 'adblock', id: 'initialize', presence });

  // Use ads library adblock determination when available
  bus.whenAdBlockIsDetected(() => {
    win.setTimeout(() => setPresence(!areRendered(googleAds)), 5000);
  });
  bus.whenAdBlockIsNotDetected(() => setPresence(false));

  // If the ads library fails to load, assume an ad blocker is preventing it from loading
  watch(
    () => notAvailable(adsLibrary),
    () => {
      eventLogger.add({ type: 'adblock', id: 'ads_library_blocked' });
      setPresence(true);
    },
    2500
  );

  // If we see ad units, there must be no adblocker present
  watch(
    () => areRendered(googleAds),
    () => {
      eventLogger.add({ type: 'adblock', id: 'google_ads_rendered' });
      setPresence(false);
    },
    100
  );
};

/**
 * isPresent
 *
 * @returns {boolean | undefined} - is an ad blocker present?
 */
const isPresent = () => presence;

/**
 * onStateChange - add a callback to be triggered when the ad
 * blocker presence state changes.
 *
 * @returns undefined
 */
const onStateChange = (callback) => callbacks.push(callback);

module.exports = {
  watch,
  cookieName,
  initialize,
  isPresent,
  onStateChange,
  setWindow: (value) => (win = value)
};
