module.exports = {
  access: 'CN_access',
  auth: 'CN_userAuth',
  openid: 'CN_token_id',
  openaccess: 'CN_token_access',
  smp: 'pay_ent_smp',
  asmp: 'pay_ent_asmp',
  usmp: 'pay_ent_usmp',
  msmp: 'pay_ent_msmp',
  sub: 'pay_ent_sub',
  pass: 'pay_ent_pass',
  sess: 'pay_ent_sess',
  tmp: 'pay_ent_tmp',
  events: 'pay_events',
  audit: 'pay_audit'
};
