/**
 * unitRenderTracker.tracker - This module is responsible for recording render timestamps for frequency capped presentation units.
 */
const localStorage = require('../localStorage');
const { hasValidFrequencyCap } = require('./limiter');

const store = localStorage.storageFor('unitRenderTimestamps');

const track = (unit, { read, write, isDisabled } = store) => {
  if (!hasValidFrequencyCap(unit)) return;
  if (isDisabled()) return;

  const unitRecord = read(unit.key) || {};
  unitRecord.lastRenderTimestamp = Date.now();
  write(unit.key, unitRecord);
};

module.exports = { track };
