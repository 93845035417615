/**
 * emitAnalyticsEvent - pushes a google analytics event to the
 * window.dataLayer array object.
 *
 * @param {object} payload - google analytics event payload
 * @param {object} campaign - current campaign
 * @returns undefined
 */
const emitAnalyticsEvent = ({ campaign, ...payload }) => {
  if (!Array.isArray(window.dataLayer)) return;

  window.dataLayer.push({
    ...payload,
    campaign: {
      key: campaign.key,
      name: campaign.name
    }
  });
};

module.exports = { emitAnalyticsEvent };
