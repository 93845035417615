/*
 * base64url module
 */

const encode = (payload) => btoa(payload).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
const decode = (string) => atob(string.replace(/-/g, '+').replace(/_/g, '/'));

/**
 * Converts a base64 value to a URL safe Uint8Array.
 *
 * @param {string} string - base64 value to convert.
 * @returns {Uint8Array} URL safe Uint8Array.
 *
 */
const toUInt8Array = (string) => Uint8Array.from(decode(string), (c) => c.charCodeAt(0));

module.exports = {
  encode,
  decode,
  toUInt8Array
};
