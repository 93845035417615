const tokenNames = require('./tokenNames');
const cookie = require('../../../shared/cookie');
const { codecFor } = require('../../../shared/codecs');
const { log } = require('../../../shared/errorLogger');

const requiresRevalidation = (form) => {
  const token = cookie.read(tokenNames[form]);
  if (!token) return;

  const payload = codecFor({ token }).decode({ token });
  if (!payload || !payload.exp) return;
  if (payload.iss === 'Journey') return;

  const length = payload.exp.toString().length;
  const now = new Date(Date.now());
  const currentDate =
    now.getUTCFullYear() * 10000 + (now.getUTCMonth() + 1) * 100 + now.getUTCDate();
  const exp = Number(payload.exp);

  return (length === 8 && currentDate > exp) || (length === 6 && currentDate > exp * 100 + 31);
};

const requestRevalidation = ({ client, payload = {} }) => {
  const path = '/api/payment/revalidate';
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json'
    },
    credentials: 'same-origin',
    cache: 'no-cache',
    body: JSON.stringify(payload)
  };
  return client(path, options)
    .then((response) => response.json())
    .then((body) => {
      if (body && !body.requestSuccess) console.warn(`Revalidate request: ${body.message}`);
      return body;
    })
    .catch((error) => {
      log('** Journey error: Revalidation **', error);
    });
};

/**
 * revalidateEntitlementTokens - revalidate entitlement tokens generated by the
 * payment server after they expire
 *
 * Token updates are returned via set-cookie headers.
 *
 * @param {function} client - (optional) network client - defaults to use fetch
 * @param {array<string>} acceptableForms - acceptable forms of payment
 * @param {array<string>} acceptableScopes - acceptable scopes of payment
 *
 * @returns {Promise<object>} revalidation request body
 */
const revalidateEntitlementTokens = ({ client = fetch, acceptableForms, acceptableScopes }) => {
  const revalidationForms = acceptableForms.filter(requiresRevalidation);
  if (revalidationForms.length === 0) return Promise.resolve();

  const tokens = { openid: cookie.read(tokenNames['openid']) };
  revalidationForms.forEach((form) => (tokens[form] = cookie.read(tokenNames[form])));
  return requestRevalidation({
    client,
    payload: {
      tokens,
      acceptableForms,
      acceptableScopes
    }
  });
};

module.exports = {
  requiresRevalidation,
  revalidateEntitlementTokens
};
