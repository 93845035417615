const hideDOMElement = ({ selector }) => {
  const element = window.document.querySelector(selector);
  if (!element) {
    return;
  }

  element.style.cssText += 'display: none;';
};

module.exports = { hideDOMElement };
