/**
 * formatter - This module is responsible for formatting text in the template variables.
 * This feature prevents us from hardcoding certain tags to format specifc parts
 * of our copies through mustache/templater variables.
 *
 * Supported formats:
 *
 * **this is bold text**
 * ___this is underlined text___
 * __this is italic text__
 * ~~this is striked-through text~~
 * new \n line
 *
 * Escaping:
 *
 * The formatter will not be applied to variables that start with \\.
 * The formatter will also ignore matches that start with \. i.e: \**not bolded text**
 * Escapers (\) in these cases will be removed.
 */

const clean = (string, search) => string.replace(search, '');
const matches = (string, search) => string.match(search);

// replacers

const wrapWith = (tag, leftovers) => (text) => {
  // starts with "\"
  if (matches(text, /^\\/)) {
    return clean(text, /\\/);
  }
  return `<${tag}>${clean(text, leftovers)}</${tag}>`;
};

const replaceWith = (replacement) => (text) => {
  // starts with "\\n" or "\" not followed by "n"
  if (matches(text, /^\\{2}n|\\(?!n)/)) {
    return clean(text, /\\/);
  }
  return replacement;
};

// searchers

const bold = /\\?(\*\*)(\n|.)*?(\*\*)/gm;
const underline = /\\?(___)(\n|.)*?(___)/gm;
const italic = /\\?(__)(\n|.)*?(__)/gm;
const strike = /\\?(~~)(\n|.)*?(~~)/gm;
const newLine = /\\{1,2}n/gm;

const formats = [
  {
    searcher: bold,
    replacer: wrapWith('strong', /\*\*/gm)
  },
  {
    searcher: underline,
    replacer: wrapWith('ins', /___/gm)
  },
  {
    searcher: italic,
    replacer: wrapWith('i', /__/gm)
  },
  {
    searcher: strike,
    replacer: wrapWith('del', /~~/gm)
  },
  {
    searcher: newLine,
    replacer: replaceWith('<br/>')
  }
];

/**
 * apply - formats the provided text
 *
 * @param {string} text - text
 * @returns {string} - formatted text
 */
const apply = (text) => {
  if (typeof text !== 'string') {
    return text;
  }

  // starts with "\\"
  if (matches(text, /^\\{2}/)) {
    return clean(text, /\\{2}/);
  }
  return formats.reduce(
    (result, { searcher, replacer }) => result.replace(searcher, replacer),
    text
  );
};

module.exports = {
  apply
};
