/**
 * controller - This module implements a communication protocol between
 * the compiler and external clients over the window messaging API.
 *
 * The controller listens for messages formatted:
 * {
 *   type: 'Journey',
 *   action: <string>,
 *   params: <object>
 * }
 *
 * When a known action is specified, the action is executed and passed
 * the event source, origin, and parameters. The event source could
 * be the same, a parent, or another window.
 *
 */

const queue = require('../shared/queue');
const bus = require('../shared/bus');
const resolver = require('../shared/resolver');
const containers = require('./containers');

// Messages

const emitPong = ({ window, origin, id }) =>
  window.postMessage(
    {
      type: 'Journey',
      action: 'pong',
      params: { id }
    },
    origin
  );

// Actions

/**
 * scrollIntoUnit helper
 *
 * {
 *   params:
 *    - unit: <journey unit>
 *
 * }
 *
 */
const scrollIntoUnit = (unit) => {
  const container = containers.containerFor(unit.slot);
  const element = container.locateElement();
  element && element.scrollIntoView({ block: 'center' });
};

/**
 * renderUnit action
 *
 * {
 *   type: 'Journey',
 *   action: 'renderUnit',
 *   params: {
 *     unit: <journey unit>
 *     campaign: <journey campaign>
 *   }
 * }
 *
 */
const renderUnit = ({ unit, campaign }) => {
  queue.add(() => {
    // update relevant verso state
    [
      { key: 'payment.form', target: 'payment' },
      { key: 'user.isEntitled', target: 'entitled' },
      { key: 'user.isAuthenticated', target: 'authenticated' }
    ].forEach(({ key, target }) => {
      let value = unit.targeting[target];
      // Falsey values are acceptable values
      if (value === undefined) value = campaign.targeting[target];
      if (value === undefined) return;
      bus.emitEvent({ id: 'journey.client.state.update', payload: { key, value } });
    });

    // expose all hidden slots
    bus.emitEvent({
      id: 'journey.client.state.update',
      payload: {
        key: 'payment.groupsToRender',
        value: ['ads', 'consumer-marketing', 'paywall', 'subs-cta', 'subscription-workflow']
      }
    });

    // override and re-render
    resolver.overrideUnits([unit]);
    resolver.overrideCampaign(campaign);
    bus.emitJourneyStateIsUpdated();
    scrollIntoUnit(unit);
  });
};

/**
 * ping action - used to signal to the client that the controller is
 * ready to execute actions.
 *
 * {
 *   type: 'Journey',
 *   action: 'ping',
 *   params: {
 *     id: <string> - unique identifier
 *   }
 * }
 *
 */
const ping = ({ source, origin, id }) => emitPong({ window: source, origin, id });

const actions = {
  renderUnit,
  ping
};

const acceptableOrigins = [
  'https://interfaces.conde.io',
  'https://stag-interfaces.conde.io',
  'http://localhost:8080'
];

const receiveMessage = (event) => {
  const { origin } = event;
  if (!event || !event.data || !acceptableOrigins.includes(origin)) {
    return;
  }

  const {
    source,
    data: { type, action: name, params }
  } = event;
  const action = actions[name];
  if (type !== 'Journey' || !action) {
    return;
  }

  action({ source, origin, ...params });
};

const initialize = () => {
  window.addEventListener('message', receiveMessage, false);
};

module.exports = {
  actions,
  receiveMessage,
  initialize
};
