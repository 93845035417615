const execute = (element) => {
  const scripts = Array.from(element.getElementsByTagName('script'));
  scripts.forEach((script) => {
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0] || document.documentElement;
    g.text = script.text ? `(() => {${script.text}})();` : '';
    s.parentNode.insertBefore(g, s);
  });
};

module.exports = {
  execute
};
