/**
 * userActions - This module is responsible for attaching actions to a specific user behavior.
 *
 * A user action is a condition applied to rendering or executing a unit, such as clicking on
 * an element or scrolling to a viewable element.
 *
 * User actions can be configured in unit configuration (UI) as follows:
 * {
 *   "userActions": [
 *      {
 *        "type": "clickOn",
 *        "selector": ".css-selector"
 *      },
 *      {
 *        "type": "scrollTo",
 *        "selector": ".css-selector"
 *      }
 *   ]
 * }
 *
 * In the case of this example, the unit should be rendered or executed as many times as the user
 * clicks on an element with the 'css-selector' class, or sees that element when scrolling.
 */
const bus = require('./bus');
const { isMatch, get } = require('./jdash');

/**
 * isViewable
 *
 * This function is used to determine if a DOM element is viewable
 * due to CSS visibility manipulations such as 'visibility', 'opacity', or
 * even the element being dimensionless (zero width or height). This function
 * returns true if the element is visible and it's in the viewport.
 *
 * @param {HTMLElement} element - the observed HTML element
 * @param {Window} win - the window object
 *
 * @returns {boolean} is the element viewable
 */
const isViewable = (element, win) => {
  const { opacity, visibility } = win.getComputedStyle(element);
  const rect = element.getBoundingClientRect();
  const isInViewport =
    Math.floor(rect.top) >= 0 &&
    Math.floor(rect.left) >= 0 &&
    Math.floor(rect.bottom) <= (win.innerHeight || document.documentElement.clientHeight) &&
    Math.floor(rect.right) <= (win.innerWidth || document.documentElement.clientWidth);

  return (
    isInViewport &&
    !!element.offsetHeight &&
    !!element.offsetWidth &&
    opacity !== '0' &&
    visibility !== 'hidden'
  );
};

const attachers = {
  scrollTo: ({ selector }, callback, win) => {
    if ([...win.document.querySelectorAll(selector)].some((e) => isViewable(e, win))) {
      callback();
    }
    win.document.addEventListener('scroll', () => {
      if ([...win.document.querySelectorAll(selector)].some((e) => isViewable(e, win))) {
        callback();
      }
    });
  },
  clickOn: ({ selector }, callback, win) => {
    win.document.addEventListener('click', ({ target }) => {
      if ([...win.document.querySelectorAll(selector)].includes(target)) {
        callback();
      }
    });
  },
  triggerEvent: ({ event }, callback) => bus.whenAnEventOccurs({ id: event, callback })
};

const list = (unit) => get(unit, 'configuration.userActions') || [];

const arePresent = (unit) => !!list(unit).length;

const attachments = [];
const hasAlreadyAttached = (a) => attachments.some((b) => isMatch(a, b));

const attach = (actions, callback, win = window) => {
  actions.forEach((action) => {
    const { type } = action;
    if (!type || !attachers[type] || hasAlreadyAttached({ action, callback })) return;

    attachments.push({ action, callback });
    attachers[type](action, callback, win);
  });
};

module.exports = {
  list,
  arePresent,
  attach
};
