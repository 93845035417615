const { matchesType } = require('./unitType');

/**
 * disabledUnits
 *
 * This module is responsible for determining units that have
 * been explicitly disabled.
 *
 * The evaluations performed by this module can be used by other parts of the
 * application, e.g. the composer, to prevent units from being shown.
 *
 * The decision to disable a unit is made by comparing the Copilot functional tags
 * for a piece of content with a unit's configured 'types'.
 *
 * If a Copilot functional tag matching the pattern 'disable-<type>-units' is found,
 * a unit whose 'types' include the '<type>' described by the tag will not be rendered.
 *
 * For example, to disable intender units for a particular piece of content:
 *   1. Add a functional tag "disable-intender-units" to the content in Copilot.
 *   2. Configure the template/component:
 *        {
 *          "types": [
 *            "intender"
 *          ]
 *        }
 */

/**
 * disabledUnitTypes
 *
 * Given an array of content tags, checks for tags matching the pattern 'disable-<type>-units',
 * then returns the '<type>' substring for all matches as a new array.
 *
 * @param {string[]} tags - array of content tags
 * @returns {string[]} - array of unit types that should be disabled
 */
const disabledUnitTypes = (tags = []) =>
  Array.from(tags.join(',').matchAll(/disable-(.*?)-units/g), (match) => match[1]);

/**
 * isADisabledUnit
 *
 * Given a unit and a state object, compares the state's disabledUnitTypes to the unit's types,
 * and returns true if that unit should be disabled, and false otherwise.
 *
 * @param {object} unit - a journey unit
 * @param {object} state - state object
 * @param {string[]} state.disabledUnitTypes - array of unit types that should be disabled
 * @returns {boolean} - whether the unit should be disabled
 */
const isADisabledUnit = (unit, { disabledUnitTypes = [] } = {}) => matchesType(unit, disabledUnitTypes);

module.exports = {
  disabledUnitTypes,
  isADisabledUnit
};
