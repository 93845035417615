/**
 * registry - This module is responsible for recording the presentation units that
 * have been rendered into slots and the action units that have been executed.
 */

const elements = new Map();
const units = new Map();
const slots = new Map();
const locks = new Map();
const actions = new Map();


/**
 * transform - transform object into a map-comparable primitive
 *
 * @param {object} object
 * @returns {primitive}
 */
const transform = (object) => JSON.stringify(object);

/**
 * isLocked - check to see if a slot has been locked
 *
 * @param {string} slot - slot name, i.e., a css class name
 * @returns {boolean}
 */
const isLocked = (slot) => locks.has(transform(slot));

/**
 * lock - prevents updates of the slot in the registry
 *
 * @returns undefined
 */
const lock = (slot) => {
  locks.set(transform(slot), true);
};

/**
 * unlock - allows updates of the slot in the registry
 *
 * @returns undefined
 */
const unlock = (slot) => {
  locks.delete(transform(slot));
};

/**
 * add - add a presentation unit to the registry
 *
 * @param {object} unit - journey unit
 * @param {object} element - the DOM element associated with the unit
 * @returns {boolean} is the unit added?
 */
const add = (unit, element) => {
  const { slot } = unit;
  if (isLocked(slot)) {
    return false;
  }
  const key = transform(slot);
  units.set(key, unit);
  elements.set(key, element);
  slots.set(transform(unit), slot);
  return true;
};

/**
 * isPresent - check to see if the unit is present in the registry
 *
 * @param {object} unit - journey unit
 * @returns {boolean}
 */
const isPresent = (unit) => slots.has(transform(unit));

/**
 * Empty - remove any unit in a slot in the registry
 *
 * @param {string} slot - slot name, i.e., a css class name
 * @returns undefined
 */
const empty = (slot) => {
  const tslot = transform(slot);
  const unit = units.get(tslot);
  units.delete(tslot);
  elements.delete(tslot);
  slots.delete(transform(unit));
};

/**
 * isEmpty - check to see if a slot in the registry is empty
 *           or if it contains a unit
 *
 * @param {string} slot - slot name, i.e., a css class name
 * @returns {boolean}
 */
const isEmpty = (slot) => !units.has(transform(slot));

/**
 * clear - removes all units from the registry
 *
 * @returns undefined
 */
const clear = () => {
  units.clear();
  elements.clear();
  slots.clear();
  locks.clear();
  actions.clear();
};

/**
 * addAction - add an Action unit to the registry
 *
 * @param {object} unit - action unit
 * @returns {boolean} is the unit added?
 */
const addAction = (unit) => {
  actions.set(transform(unit.data), unit);
  return true;
};

/**
 * hasExecuted - check to see if an Action has executed
 *
 * @param {object} unit - journey unit
 * @returns {boolean}
 */
const hasExecuted = (unit) => actions.has(transform(unit.data));

/**
 * unitIn - determine the unit in a slot
 *
 * @param {string} slot - slot name, i.e., a css class name
 * @returns {Object} unit
 */
const unitIn = (slot) => units.get(transform(slot));

module.exports = {
  add,
  isPresent,
  empty,
  isEmpty,
  clear,
  isLocked,
  lock,
  unlock,
  addAction,
  hasExecuted,
  unitIn,
  units: () => Array.from(units.values()),
  elements: () => Array.from(elements.values()),
  actions: () => Array.from(actions.values())
};
