/*
 * jdash - lightweight utility library
 */

const toArray = (value) => (typeof value !== 'undefined' ? [].concat(value) : []);
const deepClone = (object, reviver) => JSON.parse(JSON.stringify(object), reviver);
const escape = (string) => new Option(string).innerHTML;
const splitAt = (array, index) => [array.slice(0, index), array.slice(index)];
const get = (object, path, defaultValue) => {
  const value = path.split('.').reduce((acc, prop) => acc && acc[prop], object);
  if (defaultValue === undefined) return value;
  return value ? value : defaultValue;
};
const set = (object, path, value) => {
  const segments = path.split('.');
  const [nodePath, property] = splitAt(segments, -1);
  const node = nodePath.reduce((acc, prop) => {
    if (acc && !acc[prop]) acc[prop] = {};
    return acc[prop];
  }, object);
  if (node) node[property] = value;
  return object;
};
const pick = (a = {}, props) => props.reduce(
  (acc, p) => {
    const value = get(a, p);
    if (value === undefined) return acc;
    return set(acc, p, value);
  },
  {}
);
const omit = (object, props) =>
  pick(
    object,
    Object.keys(object).filter((key) => !props.includes(key))
  );
const stringify = (value) => (typeof value === 'object' ? JSON.stringify(value) : value);
const isArray = Array.isArray;
const isObject = (value) => value != null && typeof value === 'object';
const isDefined = (value) => typeof value !== 'undefined';
const isEmptyObject = (value) => isObject(value) && Object.keys(value).length === 0;
const isPrimative = (value) => !isObject(value);
const isUndefined = (value) => typeof value === 'undefined';
const isMatch = (a, b) => {
  if (isArray(a) && isArray(b))
    return (
      !b.find((bv) => !a.find((av) => isMatch(av, bv))) ||
      !a.find((bv) => !b.find((av) => isMatch(av, bv)))
    );
  if (isArray(a) && !isObject(b)) return !!a.find((av) => isMatch(av, b));
  if (isArray(b) && !isObject(a)) return !!b.find((bv) => isMatch(bv, a));
  if (isObject(a) && isObject(b))
    return !Object.entries(b).find(([property, value]) => !isMatch(a[property], value));
  if (isUndefined(b)) return true;
  return a === b;
};
const not = (func) => (...args) => !func(...args);
const zipObject = (keys = [], values = []) =>
  keys.reduce((acc, key, index) => {
    acc[key] = values[index];
    return acc;
  }, {});
const flatten = (array) => Array.prototype.concat.apply([], array);
const map = (object, iteratee) =>
  Object.entries(object).reduce((accumulator, [key, value]) => {
    accumulator[key] = iteratee(value, key, object);
    return accumulator;
  }, {});
const isEmpty = (collection) => Object.keys(collection).length === 0;
const isUniq = (value, index, array) => array.indexOf(value) === index;
const forEach = (object, iteratee) =>
  Object.entries(object).forEach(([key, value]) => iteratee(value, key, object));
const merge = (target, source) => {
  forEach(source, (value, key) => {
    if (isUndefined(target[key])) return target[key] = value;
    if (isPrimative(target[key])) return target[key] = value;
    if (isArray(target[key])) return target[key] = target[key].concat(value);
    if (isObject(target[key]) && isObject(value)) return merge(target[key], value);
    target[key] = value;
  });
  return target;
};
const uniq = (array) => [...new Set(array)];
const without = (target, source) => {
  const matches = (a) => (b) => isMatch(a, b);
  const anyMatch = (a) => (b) => a.some((value) => toArray(b).some(matches(value)));
  const removeMatching = (a, b) => a.filter((value) => !toArray(b).some(matches(value)));
  forEach(source, (value, key) => {
    if (isUndefined(key)) return;
    if (isPrimative(target[key])) {
      if (target[key] == value) delete target[key];
      return;
    }
    if (isArray(target[key])) {
      if (anyMatch(target[key], value)) target[key] = removeMatching(target[key], value);
      return;
    }
    if (isObject(target[key]) && isObject(value)) {
      without(target[key], value);
      if (isEmptyObject(target[key])) delete target[key];
      return;
    }
    target[key] = undefined;
  });
  return target;
};

module.exports = {
  deepClone,
  escape,
  forEach,
  flatten,
  get,
  isArray,
  isMatch,
  isEmpty,
  isObject,
  isDefined,
  isPrimative,
  isUndefined,
  isUniq,
  map,
  merge,
  not,
  pick,
  omit,
  set,
  splitAt,
  stringify,
  toArray,
  uniq,
  without,
  zipObject
};
