const rules = require('./rules');
const eventLogger = require('../../../shared/eventLogger');
const jwt = require('../../../shared/jwt');
const { log } = require('../../../shared/errorLogger');

const reconcile = ({
  client = fetch,
  clientCredentials,
  serviceURL,
  initialClientState,
  nextState,
  fingerprintID
}) => {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      Authorization: `Basic ${clientCredentials}`,
      'user-agent': 'Journey/1.0.0'
    },
    credentials: 'same-origin',
    cache: 'no-cache',
    body: JSON.stringify({
      fid: fingerprintID,
      brand: BRAND_SLUG,
      currState: { visitorState: initialClientState || {}, urls: [] },
      nextState: { visitorState: nextState, urls: [] }
    })
  };
  return client(serviceURL, options)
    .then((response) => response.json())
    .catch((error) => {
      log('** Journey error: visitiq reconcile request **', error);
    });
};

const execute = ({ rule, state: { fingerprintID }, args }) => {
  const payload = jwt.payloadFor('fsmp');
  const { isExpired } = rules[rule];
  if (!payload || !isExpired(payload, args)) return;

  jwt.remove('fsmp');
  const { clientCredentials, serviceURL } = args;
  reconcile({
    clientCredentials,
    serviceURL,
    initialClientState: payload,
    nextState: {},
    fingerprintID
  });
  eventLogger.add({
    type: 'fsmp-expired',
    payload
  });
};

module.exports = {
  execute
};
