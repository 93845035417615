/**
 * cniPaymentPayloadGenerator
 *
 * This function returns a paywall payload {exceededMax, cnt, max} based on
 * the payment state generated from [cni-payment plugin](https://github.com/CondeNast/verso/blob/master/src/plugins/cni-payment/README.md).
 * @param {object} state - state object
 * @returns {object} - paywall payload object
 */
const cniPaymentPayloadGenerator = (state) => {
  const form = state.payment;
  const { cnt, max } = state.usmp || state.asmp || {};

  if (form === 'free') {
    return {};
  }

  return {
    paywall: {
      exceededMax: form === '',
      ...(cnt && { cnt }),
      ...(max && { max })
    }
  };
};

module.exports = {
  cniPaymentPayloadGenerator
};
