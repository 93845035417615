/*
 * cookie - service for reading and writing cookies
 *
 */

const { now } = require('./dateTime');

let doc = document;

const localhosts = ['localhost', '0.0.0.0', '127.0.0.1'];

const domainOf = (location) => {
  const domain = location.hostname;
  if (!location || localhosts.includes(domain) || domain.match(/^local\./)) {
    return null;
  }

  if (!domain.match(/^(www|stag|stag2|stg)\./)) return domain;

  return domain.split('.').slice(1).join('.');
};

function read(name) {
  const cookies = doc.cookie;
  if (!cookies || !cookies.length || !name) {
    return;
  }

  const kvPairs = cookies.split(';').map((kv) => kv.split('=').map((s) => s.trim()));
  const [, value] = kvPairs.find(([key]) => key === name) || [];
  return value;
}

function write(name, value, timeToLiveInSeconds) {
  const domain = domainOf(doc.location);
  doc.cookie =
    `${name}=${value};` +
    (domain ? `domain=${domain};secure;samesite=none;` : '') +
    (timeToLiveInSeconds ? `expires=${now({ plus: timeToLiveInSeconds }).toUTCString()};` : '') +
    'path=/;';
}

function remove(name) {
  const domain = domainOf(doc.location);
  doc.cookie =
    `${name}=;` +
    (domain ? `domain=${domain};secure;samesite=none;` : '') +
    'expires=Thu, 01 Jan 1970 00:00:01 GMT;' +
    'path=/;';
}

module.exports = {
  read,
  write,
  remove,
  setDocument: (value) => (doc = value)
};
