const { cumulativeArgumentDebounce } = require('./debounce');

const BUFFER_PERIOD = 1000;

/**
 * fire
 * fires a waypoint event if unit.configuration.waypointEvent exists
 *
 * Ex.
 * const unit = {
 *   configuration: {
 *     waypointEvent: {
 *       id: 'cm-nav-cta'
 *       depth: '10'
 *       tags: 'A,B'
 *     }
 *   }
 * }
 *
 * @param {Object} unit - unit object
 * @returns {undefined} Undefined
 */
const fire = (unit) => {
  if (!unit.configuration.waypointEvent) {
    return;
  }

  waypointFunctions.fireWaypoint(unit.configuration.waypointEvent);
};

const fireWaypoint = (wpEvent) => {
  // waypoint: { id: 'cm-nav-martech-cta', tags: 'TAG_A,TAG_B', depth: 10 } => _topic=waypoint.journey&id=WPID&tags=TAG_A%2CTAG_B&depth=10
  const ev = {
    ...wpEvent,
    _topic: 'waypoint.journey'
  };

  waypointFunctions.sendEvent(ev);
};

const sendEvent = cumulativeArgumentDebounce((argList) => {
  const messages = argList.map((args) => args[0]);

  const payload = JSON.stringify(messages) || '';
  const endpointUrl = '/martech/api/funnel?_topic=waypoint.journey&api_key=d3Jlbg';

  // prefer to send via webBeacon, fall back to image pixel, then XHR
  let beaconSent = false;
  if (
    navigator &&
    typeof navigator.sendBeacon === 'function' &&
    typeof window.Blob === 'function'
  ) {
    // Creates a POST request containing a stringified JSON body with mime-type of `text/plain`
    beaconSent = navigator.sendBeacon(endpointUrl, payload);
  }

  if (!beaconSent) {
    if (payload.length < 1500) {
      const src = `${endpointUrl}&data=${encodeURIComponent(payload)}`;
      const beacon = new Image();
      beacon.src = src;
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', endpointUrl, true);
      xhr.setRequestHeader('Content-type', 'application/json');
      xhr.send(payload);
    }
  }
}, BUFFER_PERIOD);

const waypointFunctions = {
  fire,
  fireWaypoint,
  sendEvent
};

module.exports = waypointFunctions;
