/**
 * bridge - this module is responsible for creating a global (window) object that
 * exposes compiler code, so that other components such as the paywall panel, or
 * journey templates can access this code if needed.
 *
 * The creation of the object is done with a random name, this is to protect
 * the exported functionality from being removed by ad blockers.
 */
let name;
do {
  name = Math.random().toString(36).match(/[a-z]/g).join('');
} while (window[name] !== undefined);

window[name] = {};

/**
 * add - extends the bridge by adding the passed properties to the window global object
 *
 * @param {object} object - object containing keys and values to be exposed on the bridge
 * @returns {undefined} undefined
 */
const add = (object) => {
  window[name] = {
    ...window[name],
    ...object
  };
};

module.exports = {
  add,
  name
};
