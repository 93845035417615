/**
 * bucket - management of the journey only bucket used for AB test assignment
 */

const cookie = require('./cookie');
const pageview = require('./pageview');
const { oneYearInSeconds } = require('./dateTime');
const { getBucketNumber } = require('./xidBucket');

const name = 'CN_journey_bucket';
const expiration = oneYearInSeconds;
let value = parseInt(cookie.read(name));
if (!value || value < 0 || value > 100) {
  // choose a random integer between 1 and 100
  value = pageview.id % 100 + 1;

  // Legacy compatibility: use bucket computed from XID when XID is available
  const xid = cookie.read('CN_xid');
  if (xid) value = getBucketNumber(xid);

  // Persist cookie as it is classified as a strictly necessary cookie with respect to GDPR
  cookie.write(name, value.toString(), expiration);
}

module.exports = {
  name,
  value
};
