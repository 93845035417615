const JsonML = require('../../vendor/jsonml-html');
const notify = require('../../shared/notify');
const registry = require('../../shared/registry');
const templater = require('../../shared/templater');
const types = require('../../shared/types');
const oneTrust = require('../../shared/oneTrust');
const unitRenderTracker = require('../../shared/unitRenderTracker');
const scripts = require('../scripts');
const waypoints = require('../waypoints');
const analytics = require('../analytics');
const { containerFor } = require('../containers');
const { log } = require('../../shared/errorLogger');
const eventLogger = require('../../shared/eventLogger');
const suppression = require('../../shared/suppression');

const templates = VERSO_TEMPLATES;

const isSupported = (unit) => types.isATemplatePresentationUnit(unit);

const locateElement = (unit) => {
  const container = containerFor(unit.slot);
  if (!container) return;

  return container.locateElement();
};

const createElement = (unit) => {
  const container = containerFor(unit.slot);
  if (!container) return;

  return container.createElement();
};

const isSlotPresent = (slot) => containerFor(slot) && containerFor(slot).isElementPresent();

const render = (unit, state, campaign, rules, campaigns) => {
  try {
    const slot = unit.template.classname;
    if (!isSlotPresent(slot)) return;

    if (suppression.ofPaywall({ unit, rules })) {
      eventLogger.add({ type: 'suppressed-unit', name: unit.name });
      return;
    }

    notify.unitRendering(unit, campaign);
    const element = locateElement(unit) || createElement(unit);
    if (!element) return;

    const template = unit.template.markup || templates[unit.template.name];
    if (!template) return;

    if (oneTrust.userHasConsentedTo('targeting')) unitRenderTracker.track(unit);

    const html = JsonML.toHTMLText(template);
    const data = { ...unit.data, state };
    element.innerHTML = templater.render(html, data);

    analytics.setup(unit, element, state, campaign, campaigns);
    scripts.execute(element);
    waypoints.fire(unit);
    registry.add(unit, element);
    notify.unitRendered(unit, campaign);

    return element;
  } catch (error) {
    log(`** CM Error ${unit.name} **`, error);
  }
};

const empty = (slot, unit, campaign) => {
  try {
    notify.slotEmptying(unit, campaign);
    registry.empty(slot);

    const container = containerFor(slot);
    if (!container) return;

    const element = container.locateElement();
    if (!element) return;

    element.innerHTML = '';
    notify.slotEmptied(unit, campaign);
  } catch (error) {
    log(`** CM Error ${unit.name} **`, error);
  }
};

module.exports = {
  isSupported,
  locateElement,
  render,
  empty
};
