const tokenNames = require('./tokenNames');
const cookie = require('../../../shared/cookie');
const { log } = require('../../../shared/errorLogger');

const fourWeeksInSeconds = 2419200;

const requestValidation = ({ client, payload = {} }) => {
  const path = '/api/payment/validate';
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json'
    },
    credentials: 'same-origin',
    cache: 'no-cache',
    body: JSON.stringify(payload)
  };
  return client(path, options)
    .then((response) => response.json())
    .then((body) => {
      if (body && !body.requestSuccess) console.warn(`Validate request: ${body.message}`);
      cookie.write(tokenNames['audit'], 1, fourWeeksInSeconds);
      return body;
    })
    .catch((error) => {
      log('** Journey error: Validation **', error);
    });
};

/**
 * auditEntitlementTokens - Conduct an audit of potentially missing entitlement tokens when the
 * user is authenticated, but unentitled.
 *
 * Token updates are returned via set-cookie headers.
 *
 * @param {function} client - (optional) network client - defaults to use fetch
 * @param {array<string>} acceptableForms - acceptable forms of payment
 * @param {array<string>} acceptableScopes - acceptable scopes of payment
 *
 * @returns {Promise<object>} validation request body
 */
const auditEntitlementTokens = ({ client = fetch, acceptableForms, acceptableScopes }) => {
  // No audit required when one has been recently performed
  const audit = cookie.read(tokenNames['audit']);
  if (audit) {
    return Promise.resolve();
  }

  // No corrective action required unless the user is authenticated
  const openid = cookie.read(tokenNames['openid']);
  if (!openid) {
    return Promise.resolve();
  }

  // No corrective action required unless an entitlement token is missing
  const auditForms = acceptableForms.filter((form) => !cookie.read(tokenNames[form]));
  if (auditForms.length === 0) {
    return Promise.resolve();
  }

  const tokens = { openid: cookie.read(tokenNames['openid']) };
  auditForms.forEach((form) => (tokens[form] = cookie.read(tokenNames[form])));
  return requestValidation({
    client,
    payload: {
      tokens,
      acceptableForms,
      acceptableScopes
    }
  });
};

module.exports = {
  auditEntitlementTokens
};
