/**
 * JWT codec - HS256 signature
 *
 * This codec is designed to work with JSON web tokens in the browser.
 * Tokens are signed using the HMAC SHA-256 algorithm.
 */

const base64url = require('./base64url');
const convert = (string) => new window.TextEncoder().encode(string);

const serialize = JSON.stringify;
const sortedSerialize = (object) => {
  const keys = new Set();
  JSON.stringify(object, (key, value) => keys.add(key) && value);
  const sortedKeys = Array.from(keys).sort();
  return JSON.stringify(object, sortedKeys);
};

/**
 * encode - encode an object into a JWT
 *
 * @param {object} payload - the token payload
 * @param {string} key - a base64 string to sign tokens
 *
 * @returns {Promise<string>} JWT | Error message
 */
function encode({ payload, key = '', subtle = crypto.subtle, sort = true }) {
  const header = {
    alg: 'HS256',
    typ: 'JWT',
    ver: 1
  };

  let jsonHeader, jsonPayload;
  try {
    if (sort) {
      jsonHeader = sortedSerialize(header);
      jsonPayload = sortedSerialize(payload);
    } else {
      jsonHeader = serialize(header);
      jsonPayload = serialize(payload);
    }
  } catch (error) {
    return Promise.reject('Cannot JSON encode the JWT');
  }

  let encodedHeader, encodedPayload;
  try {
    encodedHeader = base64url.encode(jsonHeader);
    encodedPayload = base64url.encode(jsonPayload);
  } catch (error) {
    return Promise.reject('Cannot base64url encode the JWT');
  }

  return subtle
    .importKey('raw', convert(key), { name: 'HMAC', hash: 'SHA-256' }, false, ['sign'])
    .then((importedKey) =>
      subtle.sign('HMAC', importedKey, convert(encodedHeader + '.' + encodedPayload))
    )
    .then((signature) => base64url.encode(String.fromCharCode(...new Uint8Array(signature))))
    .then((encodedSignature) => `${encodedHeader}.${encodedPayload}.${encodedSignature}`);
}

/**
 * decode - extract the payload from a JWT into an object
 *
 * @param {string} token - a JWT
 * @returns {Object | undefined} payload
 */
function decode({ token }) {
  if (!token) {
    console.warn('Cannot decode a missing JWT token');
    return;
  }

  const [, payload] = token.split('.');
  if (!payload) {
    console.warn('Cannot decode an improperly formatted JWT token');
    return;
  }

  let jsonString;
  try {
    jsonString = base64url.decode(payload);
  } catch (error) {
    console.warn('Cannot base64 decode the JWT token');
    return;
  }

  let data;
  try {
    data = JSON.parse(jsonString);
  } catch (error) {
    console.warn('Cannot parse the JWT token as JSON');
    return;
  }

  return data;
}

/**
 * verify - verify the signature of a JWT
 *
 * @param {string} token - a JWT
 * @param {string} key - a base64 string to sign tokens
 * @returns {Promise<boolean>} is the signature verified
 */
function verify({ token, key, subtle = crypto.subtle }) {
  if (!token) return Promise.reject('Cannot verify a missing JWT token');
  if (!key) return Promise.reject('Cannot verify without a key');

  const [encodedHeader, encodedPayload, tokenSignature] = token.split('.');
  if (!tokenSignature) return Promise.reject('Cannot verify an improperly formatted JWT token');

  return subtle
    .importKey('raw', convert(key), { name: 'HMAC', hash: 'SHA-256' }, false, ['sign'])
    .then((importedKey) =>
      subtle.sign('HMAC', importedKey, convert(encodedHeader + '.' + encodedPayload))
    )
    .then((signature) => base64url.encode(String.fromCharCode(...new Uint8Array(signature))))
    .then((encodedSignature) => tokenSignature === encodedSignature);
}

/**
 * supports - determine if a token is supported by this codec
 *
 * @param {string} token - a JWT
 * @returns {boolean} token is supported
 */
function supports({ token }) {
  if (!token) return false;

  const [header] = token.split('.');
  if (!header) return false;

  try {
    const jsonString = base64url.decode(header);
    const data = JSON.parse(jsonString);
    const { alg, ver } = data;
    return alg === 'HS256' && ver === 1;
  } catch (error) {
    return false;
  }
}

/**
 * available - can the codec be used
 *
 * @param {object} config - codec configuration properties
 * @returns {boolean} codec is supported
 */
const available = (config) => config.key && crypto && crypto.subtle;

module.exports = { name: 'HS256', encode, decode, verify, supports, available };
