
const tokenNames = require('./tokenNames');
const cookie = require('../../../shared/cookie');
const { log } = require('../../../shared/errorLogger');
const eventLogger = require('../../../shared/eventLogger');

const token = (form) => cookie.read(tokenNames[form]);
const tokenIsPresent = (form) => !!token(form);
const removeToken = (form) => cookie.remove(tokenNames[form]);
const removeLegacyTokens = () => {
  if (!tokenIsPresent('access')) return;

  if (tokenIsPresent('sub')) {
    removeToken('sub');
    eventLogger.add({ type: 'token-exchange', event: { id: 'remove-token', token: 'sub' }});
  }
  if (tokenIsPresent('pass')) {
    removeToken('pass');
    eventLogger.add({ type: 'token-exchange', event: { id: 'remove-token', token: 'pass' }});
  }
};

const requestMartechAccessToken = ({ client, openaccess }) => {
  const path = '/api/access/revalidate';
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'Authorization': `Bearer ${openaccess}`,
      'user-agent': 'Journey/1.0.0'
    },
    credentials: 'same-origin',
    cache: 'no-cache',
  };
  return client(path, options)
    .then((response) => {
      eventLogger.add({ type: 'token-exchange', event: { id: 'new-token', token: 'access' }});
      return response.json();
    })
    .catch((error) => {
      log('** Journey error: Request Martech Access Token **', error);
    });
};

/**
 * exchangeEntitlementTokens - exchange legacy entitlement tokens for a Martech access token
 *
 * The Martech Access token is set via a set-cookie header on the returned response
 *
 * @param {integer} rollout - (optional) rollout percentage - defaults to 100 percent
 * @param {function} client - (optional) network client - defaults to use fetch
 *
 * @returns {Promise<object>} request body
 */
const exchangeEntitlementTokens = ({ client = fetch, rollout = 100 }) => {
  if (rollout < Math.floor(100 * Math.random())) return;

  if (!tokenIsPresent('openaccess')) return;

  if (tokenIsPresent('access')) {
    removeLegacyTokens();
  } else {
    requestMartechAccessToken(
      { client, openaccess: token('openaccess') }
    ).then(removeLegacyTokens);
  }
};

module.exports = {
  exchangeEntitlementTokens
};
