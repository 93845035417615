/**
 * oneTrust - this module is responsible for interacting with the CN OneTrust implementation,
 * mostly the window.OnetrustActiveGroups array which includes the Cookie Groups that a
 * user as given their consent to.
 *
 * For further information please see:
 * - https://cnissues.atlassian.net/wiki/spaces/PAYW/pages/15106506815/GDPR+Compliance+in+Journey
 * - https://cnissues.atlassian.net/wiki/spaces/QUAL/pages/610731143/How+to+set+up+OneTrust+Cookie+Blocking
 * - https://cnissues.atlassian.net/wiki/spaces/QUAL/pages/613515607/OneTrust+GDPR
 */

const cookieGroups = {
  strictlyNecessary: 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
  socialMediaCookies: 'C0005'
};

/**
 * userHasConsentedTo - checks whether the window.OneTrustActiveGroups array includes
 * a specific cookie group.
 *
 * @param {string} group - cookie group
 * @returns {boolean} has the user accepted the cookie group?
 */
const userHasConsentedTo = (group, win = window) =>
  !win.OnetrustActiveGroups || win.OnetrustActiveGroups.includes(cookieGroups[group]);

module.exports = { userHasConsentedTo };
