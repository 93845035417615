/**
 * token expiration - on  rule
 *
 * This rule should be provided with the 'date' parameter; essentially a date string
 * formatted as YYYY-MM-DD or YYYY/MM/DD. The date values can be marked as recurring
 * with an asterisk (*).
 *
 * Usage examples:
 *
 * - rule to expire a token on a fixed date (2021/01/01)
 * {
 *  "rule": "on",
 *  "date": "2021/01/01"
 * }
 */
// - rule to expire a token on a recurring date (first day of month)
// {
//  "rule": "on",
//  "date": "*/*/01"
// }
const dateTime = require('../../../../shared/dateTime');

const dateRegex = /([0-9]{4}|\*)[/-]([0-9]{1,2}|\*)[/-]([0-9]{1,2}|\*)/;

const getNextDate = ({ dateCreated, year, month, day }) => {
  const nextDate = dateTime.now();
  nextDate.setHours(0, 0, 0, 0);

  if (day !== '*') {
    nextDate.setDate(parseInt(day));
  }

  if (month !== '*') {
    nextDate.setMonth(parseInt(month) - 1);
  }

  if (year !== '*') {
    nextDate.setFullYear(parseInt(year));
  }

  if (day === '*' && month !== '*' && year !== '*') {
    // copy unspecified value
    nextDate.setDate(dateCreated.getDate());

    if (
      nextDate.getMonth() === dateCreated.getMonth() &&
      nextDate.getFullYear() === dateCreated.getFullYear()
    ) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
  }

  if (month === '*' && day !== '*') {
    // copy unspecified value
    nextDate.setMonth(dateCreated.getMonth());

    if (
      nextDate.getDate() <= dateCreated.getDate() &&
      nextDate.getFullYear() === dateCreated.getFullYear()
    ) {
      nextDate.setMonth(nextDate.getMonth() + 1);
    }
  }

  if (year === '*' && day !== '*') {
    // copy unspecified value
    nextDate.setFullYear(dateCreated.getFullYear());

    if (
      nextDate.getMonth() <= dateCreated.getMonth() &&
      nextDate.getDate() <= dateCreated.getDate()
    ) {
      nextDate.setFullYear(nextDate.getFullYear() + 1);
    }
  }

  return nextDate;
};

const parseDate = (dateString, payload) => {
  const [, year, month, day] = dateString.match(dateRegex);
  if (year !== '*' && month !== '*' && day !== '*') {
    return dateTime.from({ dateString });
  }

  const dateCreated = dateTime.from({ timestamp: payload.iat });
  return getNextDate({ dateCreated, year, month, day });
};

const isExpired = (payload, { date: dateString }) => {
  if (dateString) {
    const now = dateTime.now();
    now.setHours(0, 0, 0, 0);

    const date = parseDate(dateString, payload);
    date.setHours(0, 0, 0, 0);
    return now >= date;
  }
};

module.exports = {
  isExpired
};
