const cookie = require('../../../shared/cookie');
const { codecFor, bestAvailableCodec } = require('../../../shared/codecs');
const { oneYearInSeconds } = require('../../../shared/dateTime');
const { log } = require('../../../shared/errorLogger');

const notValid = (payload) =>
  !payload || typeof payload.cnts !== 'object' || !Array.isArray(payload.urls);
const notPreviouslyViewed = (content, payload) => !payload.urls.includes(content);
const samplesRemaining = (payload, counter) => payload.cnts[counter] || 0;
const noSamplesRemain = (payload, counter, max) => samplesRemaining(payload, counter) >= max;
const add = (content, payload, counter) => {
  payload.urls.push(content);
  payload.cnts[counter] = (payload.cnts[counter] || 0) + 1;
  payload.uat = Date.now();
};

/*
 * msmp payment form negotiator
 *
 * The msmp payment form is acceptable when
 *   - the token is present
 *   - when free samples remain or
 *   - when the content has already been sampled
 *
 * @param {string} signature - a unique base64url encoded string
 * @param {object} props - token properties
 * @param {string} props.counter - the counter name
 * @param {number} props.max - max number of free samples for the counter
 * @returns {function} payment acceptor
 */
const msmp = ({ signature, key, props: { counter = 'all', max = 4 } }) => {
  const content = window.location.pathname;
  const tokenName = 'pay_ent_msmp';

  const token = cookie.read(tokenName);

  let payload;
  if (token) {
    payload = codecFor({ token }).decode({ token, signature });
  }
  if (notValid(payload)) {
    const now = Date.now();
    payload = { cnts: {}, urls: [], iat: now, uat: now, iss: 'journey' };
  }
  if (notPreviouslyViewed(content, payload)) {
    if (noSamplesRemain(payload, counter, max)) return;

    add(content, payload, counter);
  }
  return () => {
    const codec = bestAvailableCodec({ signature, key });
    if (!codec) {
      return;
    }

    return codec
      .encode({ payload, signature, key })
      .then((encodedToken) => {
        if (encodedToken !== token) {
          cookie.write(tokenName, encodedToken, oneYearInSeconds);
        }
      })
      .catch((error) => {
        log('** Journey error: Token Encoding **', error);
      });
  };
};

module.exports = {
  msmp
};
