const { isMatch } = require('../shared/jdash');

/**
 * jBus - the journey event bus
 *
 * This module is responsible for providing a pub/sub interface for journey's
 * internal events. All events and their payloads are recorded and can be queried.
 */

let events = [];
let subscriptions = [];

/**
 * reset - remove the events and callbacks from the bus
 *
 * @returns {undefined}
 */
const reset = () => {
  events = [];
  subscriptions = [];
};

/**
 * on - subscribe to a bus event
 *
 * @param {object} subscription - subscription to bus events
 * @param {String} subscription.id - regular expression to match event ids
 * @param {object} subscription.payload - payload to be matched against
 * @param {function} subscription.callback - function to be called with payload when event occurs
 * @returns {function} a function that unsubscribes the callback from the event
 */
const on = ({ id = '.*', payload = {}, callback }) => {
  const subscription = { id, payload, callback };
  subscriptions.push(subscription);
  return () => {
    const out = (a) => (b) => !isMatch(a, b);
    subscriptions = subscriptions.filter(out(subscription));
  };
};

/**
 * emit - create a bus event
 *
 * @param {object} event - event to emit
 * @param {String} event.id - event identifier - lowercase, period delimited string
 * @param {object} event.payload - optional data to send with the event
 * @param {number} event.timestamp - optional epoch timestamp
 * @returns {undefined}
 */
const emit = ({ id, payload = {}, timestamp = Date.now() }) => {
  const event = { id, payload, timestamp };
  events = events.concat(event);
  subscriptions
    .filter((subscription) => id.match(new RegExp(subscription.id)))
    .filter((subscription) => isMatch(payload, subscription.payload))
    .forEach((subscription) => subscription.callback(event));
};

/**
 * history - bus events that have occurred
 *
 * @param {object} query - search conditions
 * @param {String} query.id - regular expression to filter events by id
 * @param {object} query.payload - payload to be matched against
 * @returns {Array<objects>} matching events
 *
 * # all events
 * jbus.history()
 *
 * # a single event by id
 * jbus.history({id: 'payment.updated'})
 *
 * # a multiple events by id using regular expression
 * jbus.history({id: '(payment|segments).updated'})
 *
 * # events with a matching payload
 * jbus.history(
 *  id: 'journey.unit.render.begin',
 *  payload: {data: {isModalPresent: true}}
 * )
 *
 */
const history = ({ id = '.*', payload = {} } = {}) =>
  events
    .filter((event) => event.id.match(new RegExp(id)))
    .filter((event) => isMatch(event.payload, payload));

module.exports = {
  on,
  emit,
  history,
  reset
};
