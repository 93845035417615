const { log } = require('./errorLogger');

/**
 * enforcer - This module is responsible for enforcing rules. It does this by being called
 * via hooks in the compiler code that allow the execution of configurable business logic.
 *
 * Each business rule is configured as a Rule Unit. Each Rule Unit has an associated Rule Function
 * that is executed when the rule is determined to be applicable. Rule Functions are passed a
 * variety of data, the exact data depends on the calling code. It is expected that the Function
 * will execute using these data, and may mutate these data as a side effect of operation. For
 * example, the setTargeting function is passed a set of units, the targeting of which is
 * mutated. Functions may also call out to other parts of the compiler as necessary to complete
 * work or even manipulate compiler state.
 *
 * Functions are implemented in the compiler, but configured in the UI. To be used as the
 * Function associated with a RuleUnit, the Function must be marked as a Rule Function. This is
 * done in the Function configuration field:
 * {
 *  "rule": true
 * }
 * This distinguishes the Rule Function from Action Functions.
 */

let registeredFunctions = {};

const enforce = ({ rule: name, rules, state, units, campaign, campaigns }) => {
  rules
    .filter((rule) => rule.data.function === name)
    .forEach((rule) => {
      const { function: name, ...parameters } = rule.data;
      const func = registeredFunctions[name];
      if (typeof func !== 'function') {
        log(`** Journey Error: Unknown Rule Function: ${name} **`);
        return;
      }

      try {
        func({ ...parameters, rule, state, units, campaign, campaigns });
      } catch (error) {
        log(`** Journey Error: Rule Function: ${name} **`, error);
      }
    });
};

const isEnabled = ({ rule: name, rules = [] }) => {
  const rule = rules.filter((rule) => rule.data.function === name).pop();
  if (!rule) return false;
  if (!rule.data.mode) return true;
  return rule.data.mode !== 'disabled';
};

const register = (functions) => {
  registeredFunctions = functions;
};

module.exports = {
  register,
  enforce,
  isEnabled
};
