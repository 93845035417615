/*
 * Unblocker - restore elements that have been hidden
 *
 * Ad blocking extensions inject style sheets that hide elements using CSS.
 * These cannot be overridden using CSS alone. To work around this
 * limitation, we use JavaScript to:
 * 1) find elements that should be viewable but are not
 * 2) remove classes that hide the element using styles
 * 3) copy the styles that don't hide the element
 * 4) re-label the element with a replacement class
 */

// a list of classes that should be viewable but might be blocked
const viewableClasses = [
  'ad-stickyhero',
  'ad-stickyhero--standard',
  'ad--out-of-page',
  'ad--in-content',
  'ad--hero',
  'ad--rail',
  'ad--aside',
  'ad--mid-content',
  'ad--footer',
  'cm-footer__failsafe',
  'consumer-marketing-unit',
  'consumer-marketing-unit--cm-footer',
  'consumer-marketing-unit--display-rail',
  'consumer-marketing-unit--display',
  'consumer-marketing-unit--display-hero',
  'consumer-marketing-unit--article-mid-content',
  'journey-unit',
  'journey-template--in-content',
  'journey-template--footer',
  'paywall-bar',
  'cm-footer',
  'paywall-registration-gate',
  'persistent-top',
  'persistent-bottom',
  'paywall-bar--visible',
  'cm-hero-wrapper',
  'cm-footer-container',
  'paywall-bar-failsafe',
  'paywall-bar--expanded'
];

// a mapping between blocked classes and replacement classes
const replacementClasses = {};
const createReplacement = (className) => {
  replacementClasses[className] =
    replacementClasses[className] || Math.random().toString(26).substr(2, 12).replace(/\d+/g, '');
};
const replacementFor = (className) => replacementClasses[className] || className;

const selectorFor = (selector) =>
  viewableClasses.reduce((accumulator, identifier) => {
    if (
      accumulator.match(identifier) &&
      !accumulator.match(new RegExp(`[a-zA-Z_-]${identifier}`)) &&
      !accumulator.match(new RegExp(`${identifier}[a-zA-Z_-]`))
    ) {
      return accumulator.replace(identifier, replacementFor(identifier));
    } else {
      return accumulator;
    }
  }, selector);

const elementsWith = (className) => Array.from(document.getElementsByClassName(className));

const isViewable = (element) => {
  const { display, visibility, opacity, filter } = window.getComputedStyle(element);
  return display !== 'none' && visibility !== 'hidden' && opacity !== 0 && filter !== 'opacity(0)';
};
const isHidden = (element) => !isViewable(element);

const filteredStyles = (element) => {
  const styles = window.getComputedStyle(element);
  delete styles.display;
  delete styles.visibility;
  delete styles.opacity;
  delete styles.filter;
  return styles;
};

const reveal = (className) => (element) => {
  element.style = filteredStyles(element);
  element.classList.remove(className);
  createReplacement(className);
  element.classList.add(replacementFor(className));
};

const unblock = (className) => elementsWith(className).filter(isHidden).forEach(reveal(className));

const execute = () => viewableClasses.forEach(unblock);

module.exports = {
  execute,
  selectorFor,
  replacementClasses
};
