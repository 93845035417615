const { matchesType } = require('./unitType');
const bus = require('./bus');
const enforcer = require('./enforcer');
const localStorage = require('./localStorage');
const { isAPresentationUnit, isAnActionUnit } = require('./types');

/**
 * suppression - This module manages logic to determine if
 *   - a presentation unit needs to be supressed from rendering
 *   - an action unit needs to be supressed from executing
 *   - a campaign needs to be supressed from resolving
 */

const store = localStorage.storageFor('journey');

const isPaywallSuppressionDisabled = () =>
  store.read('paywall-suppression') === false ||
  new URL(window.location).searchParams.get('journey-paywall-suppression') === 'false';

const isASuppressibleUnit = (unit) =>
  (!!isAPresentationUnit(unit) && matchesType(unit, ['paywall'])) ||
  (!!isAnActionUnit(unit) && matchesType(unit, ['hide-content']));

/**
 * suppression.ofPaywall - determine if a unit should be suppressed from rendering or executing.
 *
 * In order for a unit to be suppressed:
 *  - suppression must be enabled by compiler configuration or via a brand rule
 *  - suppression must not be disabled by local storage or query parameter
 *  - the unit must be of a suppressable type
 *  - a roadblock ad campaign must have occurred on the page
 *
 * @param {object} unit - the current unit
 * @param {object} rule - the current rules
 * @returns {boolean} suppress unit?
 */
const ofPaywall = ({ unit, rules, suppressOnRoadblock = SUPPRESS_ON_ROADBLOCK }) =>
  (suppressOnRoadblock || enforcer.isEnabled({ rule: 'commercialSuppression', rules })) &&
  !isPaywallSuppressionDisabled() &&
  isASuppressibleUnit(unit) &&
  bus.hasRoadblockBeenDetected();

module.exports = {
  isPaywallSuppressionDisabled,
  ofPaywall
};
