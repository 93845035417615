const cookie = require('./cookie');
const { codecFor } = require('./codecs');

const cookieFor = (tokenName) =>
  ({
    sample: 'pay_ent_smp',
    sub: 'pay_ent_sub',
    pass: 'pay_ent_pass',
    asmp: 'pay_ent_asmp',
    usmp: 'pay_ent_usmp',
    msmp: 'pay_ent_msmp',
    fsmp: 'pay_ent_fsmp',
    sess: 'pay_ent_sess',
    tmp: 'pay_ent_tmp',
    paid_ref: 'pay_paid_ref',
    access: 'CN_access'
  }[tokenName]);

const read = (name) => {
  const token = cookie.read(name);
  if (!token) return;

  const payload = codecFor({ token }).decode({ token });
  if (!payload) return;

  return payload;
};

const remove = (tokenName) => {
  const name = cookieFor(tokenName);
  const token = cookie.read(name);
  if (!token) return;

  cookie.write(name, '');
};

const tokens = () => ({
  sample: read('pay_ent_smp'),
  sub: read('pay_ent_sub'),
  pass: read('pay_ent_pass'),
  asmp: read('pay_ent_asmp'),
  usmp: read('pay_ent_usmp'),
  msmp: read('pay_ent_msmp'),
  fsmp: read('pay_ent_fsmp'),
  sess: read('pay_ent_sess'),
  tmp: read('pay_ent_tmp'),
  paid_ref: read('pay_paid_ref'),
  access: read('CN_access')
});

const payloadFor = (tokenName) => read(cookieFor(tokenName));

module.exports = {
  payload: read,
  payloadFor,
  remove,
  tokens
};
