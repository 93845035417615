const NONE = require('./none');
const HS256 = require('./hs256');

// Codecs sorted in order of preference
const knownCodecs = [HS256, NONE];

const bestAvailableCodec = (config) => knownCodecs.find((codec) => codec.available(config));
const codecFor = ({ token }) => knownCodecs.find((codec) => codec.supports({ token }));

module.exports = {
  codecFor,
  bestAvailableCodec
};
