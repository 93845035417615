const { deepClone, forEach } = require('../../../shared/jdash');

const urlFrom = (string) => new URL(string, window.location.origin);
const isRelativeUrl = (string) => !string.match(/https*:\/\//i);

/**
 * set tracking - set query parameter tracking on form urls
 *
 * @param {object} conditions (optional) - apply rule to which units
 * @param {string} conditions.url - regex specifying a matching url
 * @param {object} tracking - keys/values to set
 *
 * @param {array<objects>} units - units to be updated
 *
 * @returns {undefined} undefined
 */
const setTracking = ({ conditions = {}, tracking, units }) => {
  if (!units || !units.length || !conditions?.url) return;

  const regex = new RegExp(conditions.url);
  const withTracking = (base) => {
    try {
      const url = urlFrom(base);
      forEach(tracking, (value, param) => url.searchParams.set(param, value));
      let tracked = url.toString();
      if (isRelativeUrl(base)) tracked = tracked.replace(window.location.origin, '');
      return decodeURIComponent(tracked);
    } catch (error) {
      console.warn(error);
      return base;
    }
  };
  const reviver = (_, value) => (value.match && value.match(regex) ? withTracking(value) : value);
  units.forEach((unit) => (unit.data = deepClone(unit.data, reviver)));
};

module.exports = {
  setTracking
};
