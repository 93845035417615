/**
 * errorLogger - module responsible for managing error reporting
 *
 */

const eventLogger = require('./eventLogger');

const env = ENVIRONMENT;

/**
 * log - handle logging of an error
 *
 * @param {string} message - explanatory message
 * @param {Object} error - error object
 * @returns undefined
 */
const log = (message, error = '') => {
  if (env === 'production') {
    console.warn(message);
  } else {
    console.error(message, error);
  }

  eventLogger.add({
    type: 'compiler-error',
    event: {
      ...(message && { message }),
      ...(error && {
        error: {
          message: error.message,
          stack: error.stack
        }
      })
    }
  });
};

/**
 * initialize - begin watching for uncaught errors
 *
 * @returns undefined
 */
const initialize = () => {
  window.addEventListener('error', function (event) {
    if (!event || !event.filename || !event.filename.match(/build.*js/)) return;

    log(event.message, event.error);
  });
};

module.exports = {
  initialize,
  log
};
