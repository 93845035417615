const cookie = require('../../../shared/cookie');
const { codecFor } = require('../../../shared/codecs');

const areScopesVerified = (acceptableScopes, scopes) =>
  scopes && scopes.some((scope) => acceptableScopes.includes(scope));

/*
 * session payment form negotiator
 *
 * The sess payment form is acceptable when
 * - the token is present
 * - the acceptable scopes configured in the payment negotiation
 *   match with the scopes in the decoded token. If no applicable
 *   scopes are configured then all scopes are accepatable.
 *
 */
const sess = ({ acceptableScopes = [] }) => {
  const tokenName = 'pay_ent_sess';

  const token = cookie.read(tokenName);
  if (!token) return;

  const payload = codecFor({ token }).decode({ token });
  if (!payload || (acceptableScopes.length && !areScopesVerified(acceptableScopes, payload.scopes)))
    return;

  return () => Promise.resolve();
};

module.exports = {
  sess
};
