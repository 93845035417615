const unblocker = require('../../shared/unblocker');

const query = (selector) =>
  document.querySelector(selector) || document.querySelector(unblocker.selectorFor(selector));

/*
 * Container for consumer marking units that are rendered by Verso
 *
 * The slot is specified using the class name of the consumer marketing
 * unit rendered by Verso. For example:
 * consumer-marketing-unit--paywall-bar-call-to-action
 */
const existing = (slot) => {
  const isSupported = () => !slot.match(/\s/);
  const locateElement = () => query(`.${slot} .journey-unit`);
  const isElementPresent = () => !!locateElement(slot);
  const createElement = () => {};

  return {
    isSupported,
    locateElement,
    isElementPresent,
    createElement
  };
};

module.exports = {
  existing
};
