const notify = require('./notify');
const registry = require('./registry');
const { log } = require('./errorLogger');
const eventLogger = require('./eventLogger');
const suppression = require('./suppression');

const execute = (functions) => (unit, state, campaign, rules) => {
  const { function: name, ...parameters } = unit.data;
  const func = functions[name];
  if (typeof func !== 'function') {
    log(`** Journey Error: Unknown Function: ${name} **`);
    return;
  }

  if (suppression.ofPaywall({ unit, rules })) {
    eventLogger.add({ type: 'suppressed-unit', name: unit.name });
    return;
  }

  try {
    func({ ...parameters, state, campaign });
    notify.actionExecuted(unit, campaign);
  } catch (error) {
    log(`** Journey Error: Action Function: ${name} **`, error);
  }

  registry.addAction(unit);
};

const register = (functions) => ({
  execute: execute(functions)
});

module.exports = {
  register,
  execute
};
