/*
 * geoFrom - determines the location from which an asset is delivered
 *           and where it is received
 *
 * Fastly nodes are located throughout the world.
 * https://www.fastly.com/network-map
 * Each is identified by a three letter abbreviation.
 *
 * The node that delivers content and the location of the user can be
 * identified from custom response headers added to the response.
 * https://github.com/CondeNast/cdn-services/blob/master/vcl-includes/geolocation/server-timing.vcl
 *
 * Server timing headers are available on the PerformanceServerTiming interface.
 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceServerTiming
 *
 */
const geoFrom = (entry) => {
  const unknownLocation = {
    continent: '--',
    country: '--',
    pop: '---'
  };

  if (!entry || !entry.serverTiming) return unknownLocation;

  const timing = entry.serverTiming.find((timing) => timing.name === 'geo');
  if (!timing || !timing.description) return unknownLocation;

  const match = timing.description.match(/continent=([A-Z]*);country=([A-Z]*);pop=([A-Z]*)/);
  if (!match) return unknownLocation;

  return {
    continent: match[1],
    country: match[2],
    pop: match[3]
  };
};

module.exports = {
  geoFrom
};
