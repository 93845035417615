/**
 * paywallPayloadGenerator
 *
 * This function returns a paywall payload based on
 * the payment state(form, cnt, max)
 *
 * @param {object} state - state object
 * @returns {object} - paywall payload object
 */
const paywallPayloadGenerator = (state) => {
  const form = state.payment;
  const { [form]: { cnt, cnts, max } = {} } = state;

  if (form === 'free') {
    return {};
  }

  return {
    paywall: {
      exceededMax: form === '',
      ...(cnt && { cnt }),
      ...(cnts && { cnts }),
      ...(max && { max })
    }
  };
};

module.exports = {
  paywallPayloadGenerator
};
