const { not } = require('./jdash');

/**
 * Module
 *
 * Modules are special campaigns that can be included in other campaigns. A campaign is considered
 * a module if one of the following holds true:
 *   the campaign key begins with 'module'
 *   the campaign name begins with '[module]'
 *   the campaign configuration contains a module definition
 *
 * Modules can contain units and actions, and
 * they respect targeting. A campaign includes modules and passes parameters to those modules via
 * configuration in the campaign. For example:
 *   {
 *     includes: {
 *       'module-paywall': {
 *         enabled: true,
 *         'meter-name': 'archive',
 *         'meter-max': 3
 *       }
 *     }
 *   }
 * includes the paywall module into the campaign and passes in three values for the parameters
 * enabled, meter-name and meter-max. The enabled flag is true by default but can
 * be set to false to prevent the module from being included but retain the configuration. Each of
 * the remaining parameters is defined via configuration in the module. For example:
 *   {
 *      module: {
 *        defaults: {},
 *        uiSchema: {},
 *        schema: {
 *          properties: {
 *            'meter-max': {
 *              title: 'The number of free pieces of content allowed',
 *              type: 'string'
 *            },
 *            'meter-name': {
 *              title: 'The name of the meter for tracking purposes',
 *              type: 'string'
 *            }
 *          }
 *        }
 *      }
 *   }
 * specifies that two parameters are accepted. Parameters can be used within data fields of the units
 * and actions within the campaign. Set variables are injected into strings at locations delimited
 * by names Mustache style marks. For example:
 *   unit.data = {
 *     "function": "paymentNegotiation",
 *     "paymentForms": [ "msmp" ],
 *     "props": {
 *       "counter": "all",
 *       "max": "{{meter-max}}"
 *     }
 *   }
 * sets the meter max in the payment-negotiation action to the module parameter value.
 *
 * Modules have a scope that can either be local of global. By default, a module has local scope.
 * When a module has local scope, the module is only included in those campaigns where it is
 * explicitly included via configuration, as described above.
 *
 * Modules can also have global scope. Modules with global scope are included in all campaigns,
 * unless explicitly disabled. To give a module global scope, update the modules configuration,
 * setting the scope parameter. For example:
 *   {
 *     module: {
 *       defaults: {},
 *       uiSchema: {},
 *       schema: {},
 *       scope: 'global'
 *     }
 *   }
 *
 * To prevent a module with global scope from being included in a particular campaign, the module
 * can be explicitly disabled via campaign configuration. For example:
 *   {
 *     includes: {
 *       'module-global-brand': {
 *         enabled: false
 *       }
 *     }
 *   }
 */

/*
 * isAModule - determine if a campaign is a module
 *
 * @param {Object} campaign - a candidate campaign
 * @returns {boolean}
 */
const isAModule = (campaign) =>
  (campaign.key && !!campaign.key.match(/^module/)) ||
  (campaign.name && !!campaign.name.match(/^\[module\]/i)) ||
  (campaign.configuration && !!campaign.configuration.module);

/*
 * hasGlobalScope - determine if a campaign has global scope
 *
 * @param {Object} module - a candidate module
 * @returns {boolean}
 */
const hasGlobalScope = ({ configuration }) => configuration?.module?.scope === 'global';
const hasLocalScope = not(hasGlobalScope);

/*
 * modulesOf - determine campaign modules
 *
 * @param {Object} campaign - a campaign
 * @param {Array<Object>} campaigns - all campaigns known to the compiler
 * @returns {Array<Object>} modules that are included by the campaign
 */
const modulesOf = ({ campaign = {}, campaigns = [] }) => {
  const includes = campaign?.configuration?.includes || {};

  const globalModules = campaigns
    .filter(isAModule)
    .filter(hasGlobalScope)
    .filter(({ key }) => includes[key]?.enabled != false);

  const localModules = campaigns
    .filter(isAModule)
    .filter(hasLocalScope)
    .filter(({ key }) => includes[key])
    .filter(({ key }) => includes[key].enabled != false);

  return [...globalModules, ...localModules];
};

module.exports = {
  isAModule,
  modulesOf
};
