const cookie = require('../../shared/cookie');
const { codecFor } = require('../../shared/codecs');
const { log } = require('../../shared/errorLogger');

/*
 * Verify token signature - supports multiple signing algorithms
 *
 * Tokens are deleted if
 *   - no codec is available for the token
 *   - the token does not have the correct signature
 *
 * @param {string} signature - (optional) a unique base64url encoded string
 * @param {string} key - (optional) a unique signing key
 * @returns {undefined}
 *
 */

const verifyTokenSignature = ({ tokens, key, signature }) => {
  const verifyCodec = ({ name, token, codec }) =>
    codec
      .verify({ token, key, signature })
      .then((verified) => {
        if (!verified) cookie.remove(name);
      })
      .catch((error) => {
        log('** Journey error: Invalid token **', error);
        cookie.remove(name);
      });

  const processedTokens = tokens
    .map((name) => ({ name, token: cookie.read(name) }))
    .map(({ name, token }) => ({ name, token, codec: codecFor({ token }) }));

  processedTokens.filter(({ codec }) => !codec).forEach(({ name }) => cookie.remove(name));
  const promises = processedTokens.filter(({ codec }) => !!codec).map(verifyCodec);

  return Promise.all(promises);
};

module.exports = {
  verifyTokenSignature
};
