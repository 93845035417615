const base64url = require('./base64url');
const uInt8Array = require('./uInt8Array');

/**
 * Generate a random initialization vector.
 *
 * @returns {Promise<Uint8Array>} - initialization vector.
 *
 */
const generateIv = (crypto = window.crypto) => crypto.getRandomValues(new Uint8Array(16));

/**
 * Converts key into a `CryptoKey` object.
 *
 * @returns {Promise<CryptoKey>} - `CryptoKey` object to use for `encrypt` and `decrypt`.
 *
 */
const createCryptoKey = (key, subtle = crypto.subtle) => {
  return subtle.importKey(
    'raw',
    base64url.toUInt8Array(key),
    {
      name: 'AES-CTR'
    },
    false,
    ['encrypt', 'decrypt']
  );
};

/**
 * Encrypts a plaintext using the AES-128-CTR algorithm.
 *
 * @param {CryptoKey} key - key to be used for encryption.
 * @param {Uint8Array} iv - initialization vector.
 * @param {String} plaintext - plaintext to be encrypted.
 * @returns {string} the ciphertext.
 */
const encrypt = (key, iv, plaintext, subtle = crypto.subtle) => {
  return subtle
    .encrypt(
      {
        name: 'AES-CTR',
        counter: iv,
        length: 128
      },
      key,
      uInt8Array.encode(plaintext)
    )
    .then((buffer) => new Uint8Array(buffer))
    .then((ciphertext) => uInt8Array.toBase64(ciphertext));
};

/**
 * Decrypts a ciphertext using the AES-128-CTR algorithm.
 *
 * @param {CryptoKey} key - key to be used for decryption.
 * @param {Uint8Array} iv - initialization vector.
 * @param {String} ciphertext - ciphertext to be decrypted.
 * @returns {string} the plaintext.
 */
const decrypt = (key, iv, ciphertext, subtle = crypto.subtle) => {
  return subtle
    .decrypt(
      {
        name: 'AES-CTR',
        counter: iv,
        length: 128
      },
      key,
      base64url.toUInt8Array(ciphertext)
    )
    .then((buffer) => new Uint8Array(buffer))
    .then((plaintext) => uInt8Array.toString(plaintext));
};

/**
 * available - can the cipher be used
 *
 * @param {object} config - cipher configuration properties
 * @returns {boolean} cipher is supported
 */
const available = (config) => config.key && crypto && crypto.subtle;

module.exports = {
  createCryptoKey,
  generateIv,
  encrypt,
  decrypt,
  available
};
