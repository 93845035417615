/*
 *  panel - a light-weight compiler-delivered paywall panel
 */

const bridge = require('../shared/bridge');
const types = require('../shared/types');

const render = console.log;

const initialize = () => {
  if (!window.document.location.search.match(/journey(?!-)/)) return;

  const journey = window[bridge.name];
  if (!journey) return;

  const state = () => journey.state.getCurrentState();
  const campaign = () => journey.resolver.getCurrentCampaign();

  const fetchers = {
    Campaign: () => campaign(),
    State: () => state(),
    Units: () =>
      journey.resolver.resolveUnits({
        type: types.isATemplatePresentationUnit,
        campaign: campaign(),
        state: state()
      }),
    Actions: () =>
      journey.resolver.resolveUnits({
        type: types.isAnActionUnit,
        campaign: campaign(),
        state: state()
      }),
    Campaigns: () => journey.campaigns.map((campaign) => campaign.name),
    Events: () => journey.eventLogger.events
  };

  setTimeout(() => {
    Object.keys(fetchers).forEach((name) => render(name, fetchers[name]()));
  }, 7500);
};

module.exports = {
  initialize
};
