/*
 * Free payment form negotiator
 *
 * The free payment form is always acceptable.
 *
 * @returns {function} payment acceptor
 */
const free = () => () => Promise.resolve();

module.exports = {
  free
};
