const { matchesType } = require('../../../shared/unitType');
const { merge, without } = require('../../../shared/jdash');

/**
 * set targeting - set targeting on units
 *
 * @param {object} conditions (optional) - apply rule to which units
 * @param {string} conditions.unitType - the type of unit
 * @param {object} targeting - targeting rules to add
 * @param {string} mode (optional) - set mode: add (default), assign, remove
 *
 * @param {array<objects>} units - units to be updated
 *
 * @returns {undefined} undefined
 */
const setTargeting = ({
  conditions = {},
  targeting,
  units,
  mode = 'add'
}) => {
  units
    .filter((unit) => !conditions.unitType || matchesType(unit, conditions.unitType))
    .forEach((unit) => {
      switch (mode) {
        case 'add':
          unit.targeting = merge(unit.targeting, targeting);
          break;
        case 'assign':
          unit.targeting = {...unit.targeting, ...targeting};
          break;
        case 'remove':
          unit.targeting = without(unit.targeting, targeting);
          break;
      }
    });
};

module.exports = {
  setTargeting
};
