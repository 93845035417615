/**
 * removeQueryParams - Deletes each of the supplied parameters from window.location
 *
 * @param {string[]} queryParams - array of the query parameters to remove
 */
const removeQueryParams = ({ queryParams = [] }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  queryParams.forEach((param) => urlSearchParams.delete(param));

  const newSearch = urlSearchParams.toString().length > 0 ? `?${urlSearchParams.toString()}` : '';

  // Generate a new URL with the desired params removed.
  const newUrl = window.location.href.replace(window.location.search, newSearch);

  // Replace the current history entry with the new url. This ensures that the
  // user cannot use the 'back' button to re-acquire the stripped param(s).
  window.history.replaceState(null, '', newUrl);
};

module.exports = { removeQueryParams };
