/**
 *
 * @param {function} client - (optional) network client - defaults to use fetch
 * @param {string} clientCredentials - Basic Auth token
 * @param {string} serviceURL - VisitIQ Service URL
 * @param {object} initialClientState - last synced token value
 * @param {object} nextState - latest updated token value
 * @param {string} fingerprintID
 * @returns {Promise<void | void> | Promise<{any}>}
 */
const reconcile = async ({
  client,
  clientCredentials,
  serviceURL,
  initialClientState,
  nextState,
  fingerprintID
}) => {
  const path = serviceURL;
  const maxAttempts = 3;
  let attempts = 0;
  let delayInMilliseconds = 2000;

  const requestBody = {
    fid: fingerprintID,
    brand: BRAND_SLUG,
    currState: { visitorState: initialClientState || {}, urls: [] },
    nextState: { visitorState: nextState, urls: [] }
  };
  const request = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      Authorization: `Basic ${clientCredentials}`,
      'user-agent': 'Journey/1.0.0'
    },
    credentials: 'same-origin',
    cache: 'no-cache',
    body: JSON.stringify(requestBody)
  };

  while (attempts < maxAttempts) {
    try {
      const response = await client(path, request);
      const responseBody = await response.json();
      return {
        request: requestBody,
        response: responseBody
      };
    } catch (error) {
      attempts += 1;
      if (attempts >= maxAttempts) {
        throw error;
      }
      console.warn(`** reconcile attempt ${attempts} failed **`, error);
      // Exponential backoff
      await new Promise((resolve) => setTimeout(resolve, delayInMilliseconds));
      delayInMilliseconds *= 2; // Double the delay for the next attempt
    }
  }
};

module.exports = {
  reconcile
};
