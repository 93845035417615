const { generatorFor } = require('../shared/generators');
const observer = require('../shared/observer');
const eventLogger = require('../shared/eventLogger');
const bus = require('../shared/bus');
const { getCurrentState } = require('../verso/state');
const { log } = require('../shared/errorLogger');
const { activeFeatures } = require('../shared/features');

const FIELDS_TO_OMIT = ['type', 'selector', 'payload', 'name'];
const paywallBarStates = { expanded: 'expanded', collapsed: 'collapsed' };

const pushToGTM = (config, payload = {}) => {
  (window.dataLayer || []).push({
    event: `${config.name}-${config.type}`,
    ...payload
  });
};

const pushToLog = (config, payload = {}) => {
  eventLogger.add({
    type: 'analytics-event',
    event: config,
    ...payload
  });
};

const sendSnowplowEvent = (config, payload = {}) => {
  if (!window.trackSelfDescribingEvent) return;

  try {
    const event = {
      schema: 'iglu:com.condenast/messaging_unit_event/jsonschema/3-0-3',
      data: {
        campaign_key: config.campaignKey,
        campaign_name: config.campaignName,
        is_paywalled: config.isPaywalled,
        paywall_pageview_id: config.pageviewId,
        paywall_source: config.paywallSource,
        subject: config.name,
        type: config.type,
        state: config.state,
        ...payload
      }
    };
    window.trackSelfDescribingEvent({ event });
    eventLogger.add({ type: 'snowplow-event', event });
  } catch (err) {
    log('** Journey error: Failed to send Snowplow Event **', err);
  }
};

const pushToSnowplow = (config, payload) => {
  if (!window.snowplowQueue) return;

  try {
    window.snowplowQueue.push(() => sendSnowplowEvent(config, payload));
  } catch (err) {
    log('** Journey error: Failed to add event to Snowplow Queue **', err);
  }
};

/**
 * getSourceFrom - looks for the closest anchor tag within the element that includes
 * a "source" query parameter in its href attribute, and returns the value of that
 * query parameter.
 *
 * If the element is an anchor tag that has a sourced href, it will be used to
 * return a source, otherwise this function will look for other elements that match
 * the criteria inside the passed HTML element.
 *
 * @param {HTMLElement} element - HTML DOM element
 * @returns {string} - retrieved source from the passed element
 */
const getSourceFrom = (element) => {
  if (!element) return '';

  const sourceQueryRx = /[?&]source=([^&]+).*$/;
  const isSourcedLink = (node) => sourceQueryRx.test(decodeURIComponent(node.getAttribute('href')));
  let anchor;

  if (isSourcedLink(element)) {
    anchor = element;
  } else {
    anchor = Array.from(element.querySelectorAll('a[href]')).find(isSourcedLink);
  }

  if (!anchor) return '';

  const source = decodeURIComponent(anchor.getAttribute('href')).match(sourceQueryRx);
  return source ? source[1] : '';
};

const send = (config, state) => {
  const generator = generatorFor(config.payload);
  const payload = generator ? generator(state) : {};
  Object.entries(config)
    .filter(([key]) => !FIELDS_TO_OMIT.includes(key))
    .forEach(([key, value]) => (payload[key] = value));

  pushToGTM(config, payload);
  pushToLog(config, payload);
  pushToSnowplow(config, generatorFor('snowplowPayloadGenerator')(state));
};

const getPaywallBarStateFrom = (parentElement, childElement = null) => {
  if (!parentElement) return;

  // identifies paywall bar units using aria-expanded attribute
  const paywallBarUnit = parentElement.querySelector('[aria-expanded]');
  if (!paywallBarUnit) return;

  const isExpanded = paywallBarUnit.getAttribute('aria-expanded') === 'true';

  // handles flipping of paywall-bar state when carat icon is clicked
  const isCaratIcon = childElement ? childElement.getAttribute('aria-expanded') !== null : false;
  if (isCaratIcon) {
    return isExpanded ? paywallBarStates.collapsed : paywallBarStates.expanded;
  }

  return isExpanded ? paywallBarStates.expanded : paywallBarStates.collapsed;
};

/**
 * Setup the impression and click events
 *
 * @param unit {Object} - unit in question
 * @param parentElement {Element} - parent DOM element
 * @param state {Object} - current state
 */
const setup = (unit, parentElement, state, campaign, campaigns = []) => {
  const { configuration: config, component, slot } = unit;
  if (!config) return;

  const { analytics: { impressionEvent, clickEvent } = {} } = config;
  const pageviewId = window?.cns?.library?.runtimeId;
  const features = activeFeatures({ campaign, campaigns, state });
  const isPaywalled = features.includes('paywall');

  const impressionPayload = () => {
    const source = getSourceFrom(parentElement.firstChild);
    const paywallBarState = getPaywallBarStateFrom(parentElement);
    const payload = {
      type: 'impression',
      ...impressionEvent,
      ...(pageviewId && { pageviewId }),
      // The paywallSource label is used here due to technical limitations of the existing GTM/GA
      // analytics configuration that is outside of our control to change and would be costly to
      // change.  Source is in fact independent of the paywall and present on all journey units.
      ...(source && { paywallSource: source }),
      campaignKey: campaign.key,
      campaignName: campaign.name,
      ...(paywallBarState && { state: paywallBarState }),
      isPaywalled
    };
    return payload;
  };

  if (impressionEvent) {
    if (component && slot === 'NavRollover') {
      const unsubscribe = bus.whenJourneyComponentNavRolloverAppears(() => {
        if (!unsubscribe) return;

        send(impressionPayload(), getCurrentState());
        unsubscribe();
      });
    } else {
      observer.onElementAppearance(parentElement.firstChild, () =>
        send(impressionPayload(), state)
      );
    }
  }

  if (clickEvent) {
    // handle chevron button click event on paywall bar units
    let paywallBarChevronClick = [];
    if (component && slot === 'PaywallBar') {
      paywallBarChevronClick = [
        {
          name: 'paywall-bar-chevron-button',
          selector: '[class*=PaywallBarChevronButton]'
        }
      ];
    }

    const clickEventConfigs = [].concat(clickEvent, paywallBarChevronClick);

    clickEventConfigs.forEach((config) => {
      const elementsWithListeners = new WeakSet();

      const addListenersToElements = () => {
        const elements = parentElement.querySelectorAll(config.selector) || [];
        // when there are multiple elements sharing the same class name (in case of different devices),
        // add event listeners to each of the element
        elements.forEach((element) => {
          if (elementsWithListeners.has(element)) return;
          element.addEventListener('click', () => {
            const source = getSourceFrom(element);
            const paywallBarState = getPaywallBarStateFrom(parentElement, element);
            const payload = {
              type: 'click',
              ...config,
              ...(pageviewId && { pageviewId }),
              // The paywallSource label is used here due to technical limitations of the existing GTM/GA
              // analytics configuration that is outside of our control to change and would be costly to
              // change.  Source is in fact independent of the paywall and present on all journey units.
              ...(source && { paywallSource: source }),
              campaignKey: campaign.key,
              campaignName: campaign.name,
              ...(paywallBarState && { state: paywallBarState }),
              isPaywalled
            };
            send(payload, state);
          });
          elementsWithListeners.add(element);
        });
      };

      addListenersToElements();

      const mutationObserver = new MutationObserver(addListenersToElements);
      mutationObserver.observe(parentElement, { childList: true, subtree: true });
    });
  }
};

module.exports = {
  send,
  setup
};
