const { analyzer: schedule } = require('@condenast/journey-schedule-logic');
const { matchingUnit, matchingUnits } = require('./targeting');
const { deepClone, flatten, not } = require('./jdash');
const { isAModule, modulesOf } = require('./module');
const { isADisabledUnit } = require('./disabledUnits');
const injector = require('./injector');
const enforcer = require('./enforcer');
const { isARuleUnit } = require('./types');

/**
 * resolver - This module is responsible for the logic to determine the
 * campaign and units that are appropriate to render.
 */

const inPriorityOrder = (a, b) => (a.data.priority || Infinity) - (b.data.priority || Infinity);

const nullCampaign = { name: 'no campaign resolved', units: [] };

let currentCampaign = nullCampaign;
const getCurrentCampaign = () => currentCampaign;

let currentRules = [];
const getCurrentRules = () => currentRules;

let campaignOverride;
const overrideCampaign = (campaign) => (campaignOverride = campaign);

let unitOverrides = [];
const overrideUnits = (units) => (unitOverrides = units);

/**
 * resolveCampaign - determine the campaign that best matches the state
 *
 * @param {Array<Object>} campaigns - list of candidate campaigns
 * @param {Object} state (optional) - the current state
 * @returns {Object} campaign
 */
const resolveCampaign = ({ campaigns, state }) => {
  const activeCampaigns = campaigns.filter(schedule.isActive).filter(not(isAModule));
  currentCampaign = campaignOverride || matchingUnit(activeCampaigns, state) || nullCampaign;
  return currentCampaign;
};

/**
 * resolveModules - determine the modules of a campaign that match the state
 *
 * @param {Object} campaign - the current campaign
 * @param {Object} campaigns - all campaigns
 * @param {Object} state (optional) - the current state
 * @returns {Object} campaign
 */
const resolveModules = ({ campaign, campaigns, state }) => {
  const modules = modulesOf({ campaign, campaigns });
  const activeModules = modules.filter(schedule.isActive).filter(isAModule);
  return state ? matchingUnits(activeModules, state) : activeModules;
};

/**
 * resolveUnit - determine the unit in a campaign to render
 *
 * @param {function} type - a unit filter
 * @param {Object} campaign - the current campaign
 * @param {Array<Object>} rules - the current rules
 * @param {Object} campaigns - all campaigns
 * @param {Object} state (optional) - the current state
 * @returns {Object} unit
 */
const resolveUnit = ({ type, campaign, rules, campaigns, state }) => {
  const modules = resolveModules({ campaign, campaigns, state });
  const moduleUnits = flatten(
    modules.map((module) =>
      module.units.map((unit) => injector.set({ campaign, module, unit, state }))
    )
  );
  const candidates = [...moduleUnits, ...campaign.units]
    .filter(type)
    .filter((unit) => !isADisabledUnit(unit, state))
    .map(deepClone);

  if (type !== isARuleUnit) {
    if (!rules) rules = resolveRules({ campaign, campaigns, state });
    enforcer.enforce({
      rule: 'setTargeting',
      rules,
      units: candidates,
      campaign,
      campaigns,
      state
    });
    enforcer.enforce({ rule: 'setTracking', rules, units: candidates, campaign, campaigns, state });
  }

  let match = state ? matchingUnit(candidates, state) : candidates[0];
  if (match) match = injector.set({ campaign, unit: match, state });
  const override = unitOverrides.filter(type)[0];
  const unit = override ? override : match;
  return unit;
};

/**
 * resolveUnits - determine the units in a campaign to render
 *
 * @param {function} type - a unit filter
 * @param {Object} campaign - the current campaign
 * @param {Array<Object>} rules - the current rules
 * @param {Object} campaigns - all campaigns
 * @param {Object} state (optional) - the current state
 * @returns {Array<Object>} units
 */
const resolveUnits = ({ type, campaign, rules, campaigns, state }) => {
  const modules = resolveModules({ campaign, campaigns, state });
  const moduleUnits = flatten(
    modules.map((module) =>
      module.units.map((unit) => injector.set({ campaign, module, unit, state }))
    )
  );
  const candidates = [...moduleUnits, ...campaign.units]
    .filter(type)
    .filter((unit) => !isADisabledUnit(unit, state))
    .map(deepClone);

  if (type !== isARuleUnit) {
    if (!rules) rules = resolveRules({ campaign, campaigns, state });
    enforcer.enforce({
      rule: 'setTargeting',
      rules,
      units: candidates,
      campaign,
      campaigns,
      state
    });
    enforcer.enforce({ rule: 'setTracking', rules, units: candidates, campaign, campaigns, state });
  }

  let matches = state ? matchingUnits(candidates, state) : candidates;
  matches = matches.map((unit) => injector.set({ campaign, unit, state }));
  const overrides = unitOverrides.filter(type);
  const units = overrides.length ? overrides : matches;
  return units.sort(inPriorityOrder);
};

/**
 * resolveRules - determine the currently applicable rules
 *
 * @param {Object} campaign - the current campaign
 * @param {Object} campaigns - all campaigns
 * @param {Object} state (optional) - the current state
 * @returns {Array<Object>} rules
 */
const resolveRules = ({ campaign, campaigns, state }) => {
  currentRules = resolveUnits({ type: isARuleUnit, state, campaign, campaigns });
  return currentRules;
};

module.exports = {
  nullCampaign,
  resolveCampaign,
  overrideCampaign,
  getCurrentCampaign,
  resolveUnit,
  resolveUnits,
  overrideUnits,
  resolveModules,
  resolveRules,
  getCurrentRules
};
