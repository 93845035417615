/**
 * device - device specific state
 *
 * This module is responsible for determining details of the rendering device.
 *
 */

let operatingSystem;
const operatingSystems = [
  {
    name: 'iOS',
    pattern: /(iPhone|iPad|iPod)/i
  },
  {
    name: 'Android',
    pattern: /Android/i
  }
];

let isWebCrawler;
const webCrawlerPattern = /Googlebot|AdsBot-Google|Mediapartners|DuckDuckBot|bingbot|Slurp|ia_archiver|facebot|YandexBot/i;

let currentViewport;
const viewports = [
  {
    name: 'desktop',
    query: 'screen and (min-width: 1024px)'
  },
  {
    name: 'tablet',
    query: 'screen and (min-width: 768px) and (max-width: 1023px)'
  },
  {
    name: 'mobile',
    query: 'screen and (max-width: 767px)'
  }
];

const initialize = ({ userAgent = window.navigator.userAgent } = {}) => {
  isWebCrawler = webCrawlerPattern.test(userAgent);

  operatingSystems.forEach(({ name, pattern }) => {
    if (userAgent.match(pattern)) operatingSystem = name;
  });

  viewports.forEach(({ name, query }) => {
    if (!window.matchMedia) return;
    const list = window.matchMedia(query);
    if (!list) return;
    if (list.matches) currentViewport = name;
    const listener = ({ matches }) => {
      if (matches) currentViewport = name;
    };
    if (list.addEventListener) list.addEventListener('change', listener);
  });
};

const details = () => ({
  os: {
    name: operatingSystem
  },
  viewport: currentViewport,
  isWebCrawler
});

module.exports = {
  initialize,
  details,
  viewports,
  operatingSystems,
  get viewport() {
    return details().viewport;
  },
  get os() {
    return details().os;
  },
  get isWebCrawler() {
    return details().isWebCrawler;
  }
};
