/**
 * notify - this module is responsible for handling notifications through
 * a bus adapter on the client communication bus (e.g., cnBus for Verso).
 */

const bus = require('./bus');
const eventLogger = require('./eventLogger');
const { isAnActionUnit, isAPresentationUnit } = require('./types');
const { pick } = require('./jdash');

const loggerIncludes = [
  'unit',
  'data.isModalPresent',
  'renderer',
  'executer',
  'function',
  'template',
  'component'
];

const payloadFor = (unit, campaign) => {
  const { configuration, component, data, function: func, template } = unit;

  return {
    campaign: pick(campaign, ['key', 'name']),
    unit: pick(unit, ['key', 'name']),
    data,
    configuration,
    ...(isAPresentationUnit(unit) && { renderer: (component && component.renderer) || 'journey' }),
    ...(isAnActionUnit(unit) && { executer: 'journey' }),
    ...(component && { component: pick(component, ['name', 'variation', 'renderer']) }),
    ...(func && { function: pick(func, ['name']) }),
    ...(template && { template: pick(template, ['name']) })
  };
};

const campaignResolved = (campaign) =>
  eventLogger.add({
    type: 'campaign-resolved',
    campaign: pick(campaign, ['name'])
  });

const actionExecuted = (unit, campaign) => {
  const payload = payloadFor(unit, campaign);
  if (bus) bus.emitJourneyActionIsExecuted(payload);
};

const unitRendering = (unit, campaign) => {
  const payload = payloadFor(unit, campaign);
  if (bus) bus.emitJourneyUnitRenderBegan(payload);
};

const unitRendered = (unit, campaign, { emit } = { emit: true }) => {
  const payload = payloadFor(unit, campaign);
  if (bus && emit) bus.emitJourneyUnitRenderEnded(payload);

  // legacy events - to be removed
  if (bus && emit) bus.emitJourneyUnitIsRendered(payload);
  eventLogger.add({ type: 'unit-rendered', ...pick(payload, loggerIncludes) });
};

const slotEmptying = (unit, campaign) => {
  const payload = payloadFor(unit, campaign);
  if (bus) bus.emitJourneyUnitEmptyBegan(payload);
};

const slotEmptied = (unit, campaign, { emit } = { emit: true }) => {
  const payload = payloadFor(unit, campaign);
  if (bus && emit) bus.emitJourneyUnitEmptyEnded(payload);

  // legacy events - to be removed
  if (bus && emit) bus.emitJourneyUnitIsRemoved(payload);
  eventLogger.add({ type: 'unit-removed', ...pick(payload, loggerIncludes) });
};

module.exports = {
  campaignResolved,
  actionExecuted,
  unitRendering,
  unitRendered,
  slotEmptying,
  slotEmptied
};
