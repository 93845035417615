const eventLogger = require('../../shared/eventLogger');

/**
 * logEvent - sends configurable events to Splunk along with the whole journey state
 *
 * @param {string} type - event type
 * @param {Object} [others] - optional configured props object
 * @param {Object} state - Journey State object
 * @returns {undefined} undefined
 */
const logEvent = ({ type, ...others }, state) => {
  if (!type) return;

  eventLogger.add({
    type,
    ...others,
    state
  });
};

module.exports = {
  logEvent
};
