const { zipObject } = require('../../../shared/jdash');

const toDateString = (unit) =>
  ({
    day: 'Date',
    hour: 'Hours',
    month: 'Month',
    year: 'FullYear'
  }[unit] || 'Date');

const addTime = (date, { count, unit }) => {
  const clone = new Date(date);
  const setTime = 'setUTC' + toDateString(unit);
  const getTime = 'getUTC' + toDateString(unit);
  clone[setTime](clone[getTime]() + parseInt(count));
  return clone;
};

const toEpochTime = (date, { count, unit }) => Date.parse(addTime(date, { count, unit }));

const sampleTag = ({ tags = [] }) =>
  tags
    .map((tag) => tag.match(/paywall-sample-for-([0-9]+)-(hour|day|month|year)/))
    .filter((match) => !!match)
    .map((match) => match && zipObject([null, 'count', 'unit'], match))
    .sort((a, b) => toEpochTime(Date.now(), b) - toEpochTime(Date.now(), a))
    .shift();

/**
 * Can the content be sampled?
 *
 * Content can be sampled if it falls within a sampling period that commences
 * when the content is published.
 *
 * The duration of the sampling period is determined by the presence of a functional
 * tag that follows the pattern:
 *   paywall-sample-for-<count>-<unit>
 * where count is a number and unit is hour(s), day(s), month(s), or year(s)
 * For example:
 *  paywall-sample-for-1-hour
 *  paywall-sample-for-5-days
 *  paywall-sample-for-2-months
 *  paywall-sample-for-1-year
 *
 * If the content is tagged with a count of 0, the content cannot be sampled.
 * For example:
 *  paywall-sample-for-0-days
 *
 * If no sample tag is added, the content can always be sampled.
 *
 * @param {Object} content - Content object
 * @param {Array} content.functionalTags - List of functional tags
 * @param {String} content.publishDate - date of publication
 * @returns {Boolean} True if content can be sampled
 */
function contentCanBeSampled(content) {
  if (!sampleTag(content)) {
    return true;
  }

  const { count, unit } = sampleTag(content);
  const sampleDeadline = toEpochTime(content.publishDate, { count, unit });
  return Date.now() < sampleDeadline;
}

module.exports = {
  addTime,
  contentCanBeSampled
};
