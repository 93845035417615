const cookie = require('../shared/cookie');
const { getSegments } = require('./segments');

const subNewsletterCampaignBy = (brandSlug) =>
  ({
    'the-new-yorker': 'CRMNYR062419',
    'vanity-fair': 'CRMVYF092120',
    wired: 'CRMWIR092120'
  }[brandSlug]);
const subNewsletterSegment = 'ref.nl.sub';

const isNewsletterCampaign = ({ brandSlug = BRAND_SLUG, location = window.location } = {}) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const campaign = urlSearchParams.get('mbid');
  if (campaign === subNewsletterCampaignBy(brandSlug)) return true;

  const segments = getSegments();
  if ((segments || []).includes(subNewsletterSegment)) return true;

  return !!cookie.read('pay_ent_tmp');
};

const queryParameter = (key, location = window.location) =>
  new URLSearchParams(location.search).get(key) || '';

const paid = (location) => {
  return !!queryParameter('utm_paidredirect', location).match(/^Paid_Soc/i)
    || !!cookie.read('pay_paid_ref');
};


module.exports = {
  paid,
  isNewsletterCampaign
};
