const eventLogger = require('./eventLogger');

/**
 * localStorage - this module is responsible for adapting the localStorage API to the journey compiler,
 * adding code protection and providing handy methods to save and manipulate data stored in the localStorage
 * object.
 */

let win = window;

/**
 * storageFor - factory function
 *
 * This function returns read, write, and remove functions that manipulate data within a localStorage item.
 * A localStorage item can be any type of data, thus the functions returned are able to manipulate primitive data
 * values as well as objects with a depth of one level.
 *
 * @param {string} item - localStorage item key
 * @returns {object} object containing read, write, and remove methods
 */
const storageFor = (item) => {
  let disabled = false;

  const disable = (message) => {
    disabled = true;
    eventLogger.add({ type: 'localStorage', id: 'disabled', item, message });
  };

  const readStore = () => {
    try {
      return JSON.parse(win.localStorage.getItem(item) || '{}');
    } catch ({message}) {
      disable(message);
      return {};
    }
  };

  const writeStore = (store = {}) => {
    try {
      win.localStorage.setItem(item, JSON.stringify(store));
      return true;
    } catch ({message}) {
      disable(message);
      return false;
    }
  };

  /**
   * read - retrieves a value from the store by a given key
   *
   * @param {string} key - key
   * @returns {any} the value stored in the store for the specified key
   */
  const read = (key) => {
    if (disabled) return;

    const store = readStore();
    return store[key];
  };

  /**
   * write - writes a key/value pair within the store
   *
   * @param {string} key - key
   * @param {any} value - value for the specified key
   * @returns {boolean} was the write operation successful?
   */
  const write = (key, value) => {
    if (disabled) return false;

    const store = readStore();
    store[key] = value;
    return writeStore(store);
  };

  /**
   * remove - removes data keys within a localStorage item
   *
   * - if a key is not specified, it'll remove the whole localStorage item
   *
   * @param {string} key - data key within a local storage item (object)
   * @returns {boolean} was the remove operation successful?
   */
  const remove = (key) => {
    if (disabled) return false;

    const store = readStore();
    if (store[key] === undefined) return false;

    delete store[key];
    return writeStore(store);
  };

  return {
    read,
    write,
    remove,
    disable,
    isDisabled: () => disabled
  };
};

module.exports = {
  storageFor,
  setWindow: (value) => (win = value)
};
