/**
 * queue - This module is a first-in first-out work queue that is
 * responsible for executing work in serial order. It is used to assure
 * that journey units are rendered one at a time when the rendering
 * requests are made from different sources.
 */

const jobs = [];
let timerId;

/**
 * isEmpty
 *
 * @returns {boolean} is the queue empty?
 */
const isEmpty = () => !jobs.length;

/**
 * add - add a job to the queue
 *
 * @param {function} job - a work item
 * @returns {integer} jobs in the queue
 */
const add = (job) => jobs.push(job);

/**
 * take - take a job from the queue
 *
 * @param
 * @returns {function} job
 */
const take = () => jobs.shift();

/**
 * clear - clear the queue of jobs
 *
 * @returns undefined
 */
const clear = () => {
  jobs.length = 0;
};

/**
 * start - start processing the job queue
 *
 * @returns undefined
 */
const start = (setInterval = window.setInterval) => {
  timerId = setInterval(() => {
    if (isEmpty()) {
      return;
    }
    const job = take();
    job();
  }, 10);
};

/**
 * stop - stop processing the job queue
 *
 * @returns undefined
 */
const stop = () => {
  if (!timerId) {
    return;
  }

  clearInterval(timerId);
  timerId = undefined;
};

module.exports = {
  isEmpty,
  add,
  take,
  clear,
  start,
  stop
};
