/**
 * defeat - This module defeats ad blocker injections
 */

// SUBS-3669: ublock origin overrides setInterval, but we can
// still access the original setInterval on an instantiated iframe.
const iframe = document.createElement('iframe');
window.document.body.append(iframe);

const setInterval =
  (iframe &&
    iframe.contentWindow &&
    iframe.contentWindow.setInterval &&
    iframe.contentWindow.setInterval.bind(window)) ||
  window.setInterval;
const setTimeout =
  (iframe &&
    iframe.contentWindow &&
    iframe.contentWindow.setTimeout &&
    iframe.contentWindow.setTimeout.bind(window)) ||
  window.setTimeout;
iframe.remove();

module.exports = {
  setInterval,
  setTimeout
};
