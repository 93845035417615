const jwt = require('../shared/jwt');
const negotiators = require('./functions/negotiators');
const brand = require('../shared/brand');

const knownForms = [
  'sample',
  'sub',
  'pass',
  'sess',
  'asmp',
  'usmp',
  'msmp',
  'fsmp',
  'tmp',
  'paid_ref',
  'access'
];

/*
 * state - the state derived from entitlement tokens
 *
 * State is derived from JWT stored in cookies, but also checks verso payment
 * state as a fallback. This is done because the Vogue Business paywall design
 * derives entitlement state from request headers during the server-side request
 * rather than from cookies in a client-side request.
 *
 * @param {Object} state - verso state
 * @returns {Object} entitlement state for all known tokens
 */
const state = ({payment = {}}) => {
  const tokens = jwt.tokens();
  const stateFor = (form) => tokens[form] || payment[form];
  const state = {};
  knownForms.filter((form) => stateFor(form)).forEach((form) => (state[form] = stateFor(form)));
  return state;
};

/*
 * determine - if a user is marked entitled in at least one entitlement system
 *
 * @param {Object} state - verso state
 * @returns {boolean} is the user considered entitled
 */
const determine = ({user = {}, payment = {}, configuration = brand.configuration || {}}) => {
  const acceptableForms = configuration.payment?.acceptableForms || [];
  const acceptsSub = acceptableForms.includes('sub');
  const acceptsPass = acceptableForms.includes('pass');
  const acceptableScopes = configuration.payment?.acceptableScopes || [];
  const receipts = configuration.access?.receipts || [];
  return !!(
    // Entitled via verso state
    user.isEntitled ||
    // Entitled via access token
    !!negotiators.access({receipts}) ||
    // Entitled via payment tokens
    (acceptsSub && !!negotiators.sub()) ||
    (acceptsPass && !!negotiators.pass({acceptableScopes})) ||
    // Entitlement via payment form
    (acceptsSub && payment.form === 'sub') ||
    (acceptsPass && payment.form === 'pass' && acceptableScopes.includes(payment.scope))
  );
};

/*
 * providers - list entitlement signaling methods
 *
 * @param {Object} state - verso state
 * @returns {Array<string>} entitlement methods
 */
const providers = ({user = {}, payment = {}, configuration = brand.configuration || {}}) => {
  const acceptableForms = configuration.payment?.acceptableForms || [];
  const acceptsSub = acceptableForms.includes('sub');
  const acceptsPass = acceptableForms.includes('pass');
  const acceptableScopes = configuration.payment?.acceptableScopes || [];
  const receipts = configuration.access?.receipts || [];
  const methods = [];

  if (user.isEntitled) methods.push('verso');
  if (negotiators.access({receipts})) methods.push('access token');
  if (acceptsSub && !!negotiators.sub()) methods.push('sub token');
  if (acceptsPass && !!negotiators.pass({acceptableScopes})) methods.push('pass token');
  if (acceptsSub && payment.form === 'sub') methods.push('sub payment');
  if (acceptsPass && payment.form === 'pass' && acceptableScopes.includes(payment.scope)) methods.push('pass payment');
  return methods;
};

module.exports = {
  determine,
  providers,
  state,
  knownForms
};
