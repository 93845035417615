/**
 * snowplowPayloadGenerator
 *
 * This function returns a Snowplow paywall payload based on
 * the payment state(form, cnt, max)
 *
 * @param {object} state - state object
 * @returns {object} - paywall payload object
 */
const snowplowPayloadGenerator = (state) => {
  const form = state.payment;
  const { [form]: { cnt, cnts } = {} } = state;
  let meter;

  if (form === 'free') {
    return {};
  }

  if (cnts || cnt) {
    meter = cnts
      ? Object.entries(cnts).map(([meter, cnt]) => ({ name: meter, count: cnt }))
      : [{ count: cnt }];
  }

  return {
    is_exceeded: form === '',
    ...(meter && { meter })
  };
};

module.exports = {
  snowplowPayloadGenerator
};
