/**
 * this module was extracted from the ads library.
 * https://github.com/CondeNast/ads/blob/master/packages/fast-ads/src/lib/debounce.js
 */

const cloneArray = (list) => Array.prototype.slice.apply(list);

const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const _this = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(_this, args), delay);
  };
};

const cumulativeArgumentDebounce = (func, delay) => {
  let inDebounce;
  const args = [];
  return function () {
    const _this = this;
    args.push(cloneArray(arguments));
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func.apply(_this, [cloneArray(args)]);
      args.length = 0;
    }, delay);
  };
};

module.exports = {
  debounce,
  cumulativeArgumentDebounce
};
