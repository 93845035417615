/**
 * Converts an Uint8Array to a String.
 *
 * @param {Uint8Array} u8 - Uint8Array to convert.
 * @returns {string} converted string.
 *
 */
const toString = (u8) => String.fromCharCode.apply(null, u8);

/**
 * Converts a Uint8Array to a URL safe Base64 encoded value.
 *
 * @param {Uint8Array} u8 - Uint8Array to convert.
 * @returns {string} URL safe Base64 encoded value.
 *
 */
const toBase64 = (u8) =>
  window.btoa(toString(u8)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

/**
 * Converts a string into an encoded Uint8Array.
 *
 * @param {string} str - string to encode.
 * @returns {Uint8Array} encoded Uint8Array.
 *
 */
const encode = (str) => new TextEncoder().encode(str);

module.exports = {
  toString,
  toBase64,
  encode
};
